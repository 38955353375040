import styled from 'styled-components'
import { toCssPrefix, breakpoints, hexToRGBA } from '@ecommerce/shared'

export const { toPrefix, cssPrefix } = toCssPrefix('AddressForm__')

export const Wrapper = styled.div`
  .${cssPrefix} {
    &save-button {
      font-size: 13px;
      padding: 6px 35px;
      height: auto;
      width: 100%;
    }
    &cancel-button {
      font-size: 13px;
      padding: 6px 35px;
      height: auto;
      margin-right: 16px;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.red};
    }
    &edit-button {
      color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
      font-size: 13px;
      margin-left: 16px;
      font-weight: 350;
      text-decoration: underline;
      padding: 0;
      background-color: transparent;
      height: auto;
    }
    &input {
      width: 100%;
      margin-bottom: 16px;
    }
    &input-full {
      width: 100%;
      margin-bottom: 16px;
    }
  }
  @media (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &save-button {
        width: auto;
      }
      &input {
        width: calc(50% - 8px);
      }
      &input-full {
        width: 100%;
      }
    }
  }
`

export const ContentButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.254px;
  margin-bottom: 24px;
  svg {
    margin-right: 8px;
  }
`

export const Location = styled.div`
  font-size: 16px;
  margin-bottom: 24px;
  font-weight: 325;
  span {
    color: ${({ theme }) => theme.colors.red};
    font-weight: 600;
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  @media (${breakpoints.desktop.min}) {
    flex-direction: row;
  }
`
const placeholderUrl =
  'https://images.ctfassets.net/16npdkkoi5mj/1pIE21tdFVCHOPcshohS7s/d8488484fd90be4db9bd52bd8d952c5c/map-placeholder.jpg?h=250'

export const MapWrapper = styled.div`
  margin: 10px 0 20px;

  .map-container {
    width: 100%;
    height: 250px;
    position: relative;
    background: url(${placeholderUrl});
    background-position: center;
    background-size: cover;
  }

  .map-instructions,
  .map-error {
    color: ${({ theme }) => theme.colors.black};
    font-size: 15px;
    text-align: center;
    transition: 0.15s;
  }

  &#map-wrapper {
    .map-error {
      margin: 15px auto;
      width: fit-content;

      span {
        color: ${({ theme }) => theme.colors.error};
        font-weight: bold;
        text-align: left;
      }

      svg {
        fill: ${({ theme }) => theme.colors.error};
      }
    }
  }
`
export const ResultsWrapper = styled.div<{ isShown: boolean }>`
  max-height: 245px;
  overflow-y: scroll;
  background: ${({ theme }) => theme.colors.grey};
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 12px 12px 16px;
  transition: 0.15s;
  position: absolute;
  margin: 0;
  top: 75px;
  opacity: ${({ isShown }) => (isShown ? '1' : '0')};
  z-index: ${({ isShown }) => (isShown ? '1' : '-1')};
  left: 0;

  button {
    font-size: 14px;
    padding: 0 8px;
    min-height: 28px;
    margin-bottom: 10px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    background: none;
    border: none;
    width: 100%;
    text-align: left;

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  .autocomplete-error {
    color: ${({ theme }) => theme.colors.red};
    font-size: 14px;
  }

  .google-disclaimer {
    position: absolute;
    bottom: 6px;
    right: 20px;
    height: auto;
    width: 110px;
    display: block;
  }
`
