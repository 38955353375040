import React from 'react'
import {
  Button,
  Checkbox,
  Geocode,
  Market,
  Order,
  OrderData,
  PaymentTypes,
  ProductCart,
  useLocation,
  Zone,
} from '@ecommerce/shared'
import { navigate } from 'gatsby'
import { CheckboxOption, toPrefix, Wrapper } from './Confirm.styled'
import { PageStaticNamesBO, PageStaticNamesCL } from '../../../../../i18n'
import { useCheckout } from '../../../checkout.context'
import PaymentHandlerButton from './PaymentHandlerButton'
import AlertMessage from '../../../../AlertMessage'

type Props = {
  disableButton: boolean
  handleChange: (name: keyof Order, value: boolean) => void
  checkStockCallback: (hasValidStock: boolean, products?: ProductCart[]) => void
  orderData: OrderData
  currentMarket: Market
  currentZone: Zone
  geocode?: Geocode
  orderId: string
  selectedPaymentType: PaymentTypes
  refSessionId: string
  refGuestUser: {
    firstName?: string
    lastName?: string
  }
}

const Confirm = ({
  disableButton,
  checkStockCallback,
  handleChange,
  orderData,
  currentMarket,
  currentZone,
  geocode,
  orderId,
  selectedPaymentType,
  refSessionId,
  refGuestUser,
}: Props) => {
  const { textByCountry } = useLocation()

  const {
    currentStep,
    isExecutingPayment,
    setCurrentStep,
    selectPaymentError,
    selectTermsError,
    setSelectTermstError,
  } = useCheckout()

  return (
    <Wrapper>
      {currentStep === 2 ? (
        <Button
          className={toPrefix('next-button')}
          onClick={() => {
            setCurrentStep(3)
          }}
          btnType="primary"
          disabled={disableButton}
          isDisabled={disableButton}
        >
          Continuar
        </Button>
      ) : (
        <>
          <CheckboxOption>
            <Checkbox
              name="marketing"
              defaultChecked
              onChange={({ target }) => {
                handleChange('marketing', target.checked)
              }}
              data-testid="checkout-promotions-checkbox"
              disabled={isExecutingPayment}
            />
            <span>Quiero recibir ofertas y promociones</span>
          </CheckboxOption>
          <CheckboxOption className={toPrefix('termCheckbox')}>
            <Checkbox
              name="terms"
              defaultValue=""
              onChange={({ target }) => {
                setSelectTermstError(false)
                handleChange('terms', target.checked)
              }}
              data-test="checkout-terms-checkbox"
              disabled={isExecutingPayment}
            />
            <span>
              He leído y acepto los&nbsp;
              <a
                className={toPrefix('link is-black')}
                href={`/${textByCountry(PageStaticNamesCL.terms, PageStaticNamesBO.terms)}`}
                target="_blank"
                rel="noreferrer"
              >
                Términos y Condiciones
              </a>
              &nbsp; y &nbsp;
              <a
                className={toPrefix('link is-black')}
                href={`/${textByCountry(PageStaticNamesCL.privacyPolicy, PageStaticNamesBO.privacyPolicy)}`}
                target="_blank"
                rel="noreferrer"
              >
                Política de Privacidad
              </a>
              .
            </span>
          </CheckboxOption>
          {selectPaymentError && (
            <AlertMessage type="error" className={toPrefix('alert-message')}>
              Selecciona un <span>medio de pago</span> para continuar.
            </AlertMessage>
          )}
          {selectTermsError && (
            <AlertMessage type="error" className={toPrefix('alert-message')}>
              Debes aceptar los <span>términos y condiciones</span> para continuar.
            </AlertMessage>
          )}
          <PaymentHandlerButton
            checkStockCallback={checkStockCallback}
            disabled={disableButton}
            orderData={orderData}
            currentCity={currentMarket}
            currentZone={currentZone}
            geocode={geocode}
            orderId={orderId}
            buttonText={
              selectedPaymentType === PaymentTypes.WEBPAY ||
              selectedPaymentType === PaymentTypes.REDENLACE ||
              selectedPaymentType === PaymentTypes.CHEK ||
              selectedPaymentType === PaymentTypes.MACH ||
              selectedPaymentType === PaymentTypes.GIFT_CARD ||
              selectedPaymentType === PaymentTypes.GIFT_CARD_CHEK ||
              selectedPaymentType === PaymentTypes.GIFT_CARD_MACH ||
              selectedPaymentType === PaymentTypes.GIFT_CARD_WEBPAY
                ? 'Pagar'
                : 'Realizar pedido'
            }
            sessionId={refSessionId}
            guestUser={refGuestUser}
            className={toPrefix('confirm-payment')}
          />
        </>
      )}
      <Button onClick={() => navigate('/', { state: {} })} btnType="tertiary">
        Seguir comprando
      </Button>
    </Wrapper>
  )
}

export default Confirm
