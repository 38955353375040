import styled from 'styled-components'
import { breakpoints } from '@ecommerce/shared'

export const Wrapper = styled.div`
  width: 100%;
  @media (${breakpoints.desktop.min}) {
    .Select__handle {
      width: 320px;
    }
  }
`
export const Label = styled.div`
  font-weight: 600;
  font-size: 17px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  line-height: 25px;
  svg {
    margin-right: 4px;
  }
  @media (${breakpoints.desktop.min}) {
    font-size: 18px;
    line-height: 22px;
  }
`

export const Description = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 325;
  line-height: 16px;
  margin-bottom: 24px;
  @media (${breakpoints.desktop.min}) {
    font-size: 13px;
    line-height: 18px;
  }
`
export const ErrorMessage = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.error};

  button {
    color: inherit;
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: underline;
  }

  &.payment--error {
    text-align: center;
    font-weight: 500;
    width: 70%;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    p {
      margin-top: 0;
      text-align: left;

      span {
        display: block;
        width: 100%;
      }
    }
  }
`
