import { PaymentMethodType, PaymentTypes } from '@ecommerce/shared'

export const getStaticPaymentMethods = (QRID?: number) => {
  const items: PaymentMethodType[] = [
    {
      value: PaymentTypes.EFECTIVO_CONTRA_ENTREGA,
      label: 'Efectivo en Bs.',
    },
    {
      value: PaymentTypes.TARJETA_CONTRA_ENTREGA,
      label: 'Tarjeta de débito/crédito en Bs.',
    },
  ]

  if (QRID) {
    items.push({
      QRID,
      value: QRID,
      label: 'Transferencia en Bs (Código QR)',
    })
  }

  return items
}
