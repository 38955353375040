import styled from 'styled-components'
import { hexToRGBA } from '@ecommerce/shared'

export const Wrapper = styled.div`
  color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
  a {
    color: ${({ theme }) => theme.colors.red};
  }
`
export const Title = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.254px;
  margin-bottom: 8px;
  svg {
    margin-right: 8px;
  }
`

export const Description = styled.div`
  font-size: 13px;
  font-weight: 325;
  line-height: 18px;
  letter-spacing: -0.06px;
  margin-bottom: 8px;
`

export const CheckboxOption = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;

  span {
    margin-left: 10px;
    color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
    font-size: 13px;
  }
`

export const BillingFields = styled.div`
  padding: 7px 0 0 0;

  .BillingFields__CheckboxOption {
    padding: 0;
    margin: 0;
  }

  .BillingFields__form {
    margin-top: 30px;

    .BillingFields__form-title {
      color: ${({ theme }) => theme.colors.platinum80};
      font-size: 13px;
    }

    /* FIXME: Remove ID */
    #BO_fields {
      .BO_City_Select {
        .Select__change-text {
          color: ${({ theme }) => theme.colors.red};
        }
      }
    }

    .BillingFields__input {
      margin: 10px 0;
    }

    .SaveBillingButton {
      margin-top: 30px;
    }
  }
`

export const BillingInfoCard = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 10px 25px;
  margin-top: 30px;

  .BillingFields__form-title {
    font-weight: 600;
  }

  .billing-form-info-text {
    margin: 4px 0;
  }

  .BillingInfoCard__edit-button {
    background: none;
    border: none;
    color: ${({ theme }) => theme.colors.red};
    margin-top: 8px;
    cursor: pointer;
    padding: 0;
  }

  .BillingInfoCard__edit-button.is-disabled {
    cursor: default;
    color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
  }
`
