import React from 'react'

type Props = {
  size?: string
}
export const LockIcon = ({ size }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M17.8333 11.1667H6.16667C5.24619 11.1667 4.5 11.9129 4.5 12.8334V18.6667C4.5 19.5872 5.24619 20.3334 6.16667 20.3334H17.8333C18.7538 20.3334 19.5 19.5872 19.5 18.6667V12.8334C19.5 11.9129 18.7538 11.1667 17.8333 11.1667Z"
      stroke="#354455"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.83337 11.1667V7.83341C7.83337 6.72835 8.27236 5.66854 9.05376 4.88714C9.83516 4.10573 10.895 3.66675 12 3.66675C13.1051 3.66675 14.1649 4.10573 14.9463 4.88714C15.7277 5.66854 16.1667 6.72835 16.1667 7.83341V11.1667"
      stroke="#354455"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
