import styled from 'styled-components'
import { hexToRGBA } from '@ecommerce/shared'

export const Wrapper = styled.div`
  width: 100%;
  color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
  .Alert__modal {
    width: 360px;
    padding: 8px 32px 32px;
  }
  .ConfirmationAlert__text {
    margin-bottom: 16px;
    span {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 125% */
      letter-spacing: 0.1px;
    }
  }
  .ConfirmationAlert__description {
    span {
      font-size: 15px;
      font-style: normal;
      font-weight: 325;
      line-height: 20px; /* 133.333% */
      letter-spacing: -0.15px;
    }
  }
`
export const AlertContent = styled.span``

export const AlertTitle = styled.span`
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
    color: ${({ theme }) => theme.colors.red};
  }
`
