import styled from 'styled-components'
import { hexToRGBA, toCssPrefix } from '@ecommerce/shared'

export const { toPrefix, cssPrefix } = toCssPrefix('AddressInfo__')

export const Wrapper = styled.div`
  color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
  .${cssPrefix} {
    &edit-button {
      font-weight: 300;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.red};
      height: auto;
      font-size: 15px;
    }
  }
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const Label = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.254px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  span:first-child {
    margin-bottom: 8px;
  }
  span {
    font-size: 15px;
    font-weight: 325;
    line-height: 20px;
    letter-spacing: -0.15px;
  }
`
