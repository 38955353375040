import React, { useEffect, useState } from 'react'
import { TextField, toCurrency, verifyRecaptcha } from '@ecommerce/shared'
import Reaptcha from 'reaptcha'
import { useFormContext } from 'react-hook-form'
import {
  Wrapper,
  Title,
  Description,
  Header,
  Content,
  FormWrapper,
  StyledButton,
  CardTitleWrapper,
  CardTitle,
  CardWrapper,
  CardCode,
  CardBodyWrapper,
  CardAppliedBalance,
  CardCreditBalance,
  MainSection,
  RemoveGiftCard,
  FooterWrapper,
} from './Giftcard.styled'
import { Icon } from '../../../../Icon/Icon'
import AlertMessage from '../../../../AlertMessage'
import Logo from '../../../../Icons/Logo'
import { useCheckout } from '../../../checkout.context'
import { useGiftCard } from './useGiftCard'
import secrets from '../../../../../config/secrets'

type Props = {
  canUndo?: boolean
  orderId: string
}

const GiftCard = ({ canUndo = true, orderId }: Props) => {
  const { COUNTRY } = secrets

  const { setValue } = useFormContext()

  const [isVerifyRecaptcha, setIsVerifyRecaptcha] = useState<boolean>(false)

  const { isExecutingPayment, getCartStateFromCommerceLayer, setCartState, orderTotal, cartState } = useCheckout()

  const { errorState, giftCard, setGiftCard, isSuccess, isLoading, onApplyGiftCard, onRemoveGiftCard } = useGiftCard({
    orderId,
  })

  const { giftCardDetail, giftCardCreditBalance } = useCheckout()

  const isDisabled = isExecutingPayment || isLoading

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    setGiftCard(value)
  }

  const applyGiftCard = () => {
    if (isVerifyRecaptcha) {
      onApplyGiftCard()
      getCartStateFromCommerceLayer(setCartState)
    }
  }
  const removeGiftCard = async () => {
    await onRemoveGiftCard()
    setIsVerifyRecaptcha(false)
    setValue('payment-type', undefined)
  }

  return (
    <Wrapper>
      <Header>
        <Title>
          <Icon iconId="gift-card" size="20" />
          Giftcard
        </Title>
        <Description>Si deseas pagar con giftcard ingresa el código</Description>
      </Header>
      <MainSection>
        <Content>
          <FormWrapper>
            <TextField
              data-testid="gift-card"
              className="input"
              placeholder="Ingresa el codigo de tu giftcard"
              type="text"
              name="gift-card"
              status={errorState.hasError ? 'error' : undefined}
              value={giftCard}
              onChange={handleChange}
              errorMessage={errorState.message}
              disabled={isSuccess || isDisabled}
            />
            {giftCard && !isVerifyRecaptcha && (
              <Reaptcha
                className="recaptcha"
                sitekey={secrets.GOOGLE_RECAPTCHA_SITE_KEY}
                onVerify={async (token) => {
                  const data = await verifyRecaptcha(COUNTRY, token)
                  if (data) setIsVerifyRecaptcha(data)
                }}
                onExpire={() => setIsVerifyRecaptcha(false)}
              />
            )}
            {!isSuccess && (
              <StyledButton
                type="button"
                isDisabled={isDisabled || !isVerifyRecaptcha}
                disabled={isDisabled || !isVerifyRecaptcha}
                onClick={(e) => {
                  e.preventDefault()
                  applyGiftCard()
                }}
              >
                Agregar
              </StyledButton>
            )}
          </FormWrapper>
          {giftCardCreditBalance() > 0 && (
            <AlertMessage type="info">Tienes un saldo a favor que podrás utilizar en tu próxima compra.</AlertMessage>
          )}
          {giftCardCreditBalance() === orderTotal && (
            <AlertMessage type="info">
              Tu giftcard cubre el monto total de la compra, no requieres seleccionar un medio de pago adicional.
            </AlertMessage>
          )}
          {isSuccess && giftCardCreditBalance() < orderTotal && (
            <AlertMessage type="error">
              El monto a pagar es superior al saldo disponible en tu giftcard.{' '}
              <span style={{ fontWeight: 'bold' }}>Selecciona un medio de pago adicional</span> para finalizar la
              compra.
            </AlertMessage>
          )}
        </Content>

        {giftCardDetail && (
          <FooterWrapper>
            <CardWrapper>
              <CardTitleWrapper>
                <CardTitle>Giftcard</CardTitle>
                <Logo />
              </CardTitleWrapper>
              <CardBodyWrapper>
                <CardCode>
                  <span>Codigo</span>
                  <span>{giftCard}</span>
                </CardCode>
                <CardAppliedBalance>
                  <span>Saldo aplicado</span>
                  <span>{toCurrency(giftCardDetail.discountAmount)}</span>
                </CardAppliedBalance>
                <CardCreditBalance>
                  <span>Saldo a favor</span>
                  {giftCardCreditBalance && <span>{toCurrency(giftCardCreditBalance())}</span>}
                </CardCreditBalance>
              </CardBodyWrapper>
            </CardWrapper>
            <RemoveGiftCard onClick={() => removeGiftCard()}>
              <Icon iconId="trash" size={20} fillColor="red" />
              <span>Eliminar como medio de pago</span>
            </RemoveGiftCard>
          </FooterWrapper>
        )}
      </MainSection>
    </Wrapper>
  )
}

export default GiftCard
