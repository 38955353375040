import styled from 'styled-components'
import { toCssPrefix, breakpoints, hexToRGBA } from '@ecommerce/shared'

export const { toPrefix, cssPrefix } = toCssPrefix('Detail__')

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => theme.colors.white};
  padding: 16px 16px 24px;
  transition: all 0.3s;
  .${cssPrefix} {
    &edit-button {
      font-size: 12px;
      font-weight: 325;
      height: auto;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.red};
    }
    &next-button {
      width: 100%;
    }
    &alert-message {
      margin-bottom: 16px;
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    padding: 16px 40px 24px;
  }
`

export const DetailTotal = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.platinum80};
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const Title = styled.h2`
  margin: 0;
  font-size: 17px;
  line-height: 25px;
  font-weight: 600;
  color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
`
