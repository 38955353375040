import { useState } from 'react'
import { Geocode, getStoredDistributionCenter, log, Market, OrderData, ProductCart, Zone } from '@ecommerce/shared'
import secrets from '../../../config/secrets'
import { useCheckout } from '../checkout.context'
import {
  checkStockStatus,
  handleCashOnDeliveryPayment,
  handleNormalPayment,
  handleQrPayment,
  isCashOnDelivery,
  processOrder,
  validateOrder,
} from '../utils'
import { ErrorLevel, ErrorSource, sendMessageToSentry } from '../../../utils/sentry'

interface UsePaymentHandlerProps {
  checkStockCallback: (hasValidStock: boolean, products?: ProductCart[]) => void
  orderData: OrderData
  currentCity: Market
  currentZone: Zone
  geocode?: Geocode
  orderId: string
  sessionId: string
  guestUser: { firstName?: string; lastName?: string }
}

const usePaymentHandler = ({
  checkStockCallback,
  orderData,
  currentCity,
  currentZone,
  geocode,
  orderId,
  sessionId,
  guestUser,
}: UsePaymentHandlerProps) => {
  const { COUNTRY: country, SECURE_ACCEPTANCE_HOSTED_CHECKOUT_URL } = secrets

  const {
    cartState: { byHash: products },
    orderTotal,
    beforeRedirectCallback,
    setIsExecutingPayment,
    useDifferentBillingData,
    setProcessErrors,
  } = useCheckout()

  const [loading, setLoading] = useState(false)

  const setHasError = (hasError: boolean) => {
    if (hasError) {
      setIsExecutingPayment(false)
    }
    return setProcessErrors((prev) => ({
      ...prev,
      payment: hasError,
    }))
  }

  const handleSubmit = async () => {
    setIsExecutingPayment(true)
    const startTime = performance.now()
    if (!validateOrder(orderTotal, currentCity, orderData, products, loading)) {
      setLoading(false)
      setProcessErrors((prev) => ({
        ...prev,
        payment: true,
      }))
      setIsExecutingPayment(false)
      return
    }
    const distributionCenter = getStoredDistributionCenter()
    try {
      setLoading(true)
      const stockCheckResult = await checkStockStatus(products, country, currentCity, checkStockCallback)
      if (stockCheckResult !== undefined) {
        setLoading(false)
        return
      }

      await processOrder(
        orderData,
        currentCity,
        currentZone,
        geocode,
        orderId,
        useDifferentBillingData,
        guestUser,
        distributionCenter,
        country,
      )

      if (orderData.isQRSelected) await handleQrPayment(orderId, country, orderData, beforeRedirectCallback)
      else if (isCashOnDelivery(orderData)) {
        await handleCashOnDeliveryPayment(orderId, beforeRedirectCallback)
      } else {
        await handleNormalPayment(
          orderId,
          currentCity,
          sessionId,
          country,
          SECURE_ACCEPTANCE_HOSTED_CHECKOUT_URL,
          beforeRedirectCallback,
        )
      }
      log.trace(`Payment handler lasted ${((performance.now() - startTime) / 1000).toFixed(2)}s to finish.`)
    } catch (e) {
      let errorMessage = e.message
      let source = ErrorSource.CLayer

      if (e.response?.data) {
        // Commerce layer error
        if (e.response.data.errors) {
          errorMessage = e.response.data.errors[0]?.detail
        } else {
          // BFF Error
          errorMessage = e.response.data.error
          source = ErrorSource.BFF
        }
      }

      setProcessErrors((prev) => ({
        ...prev,
        payment: true,
      }))

      setIsExecutingPayment(false)

      sendMessageToSentry({
        page: 'checkout',
        message: errorMessage,
        source,
        level: ErrorLevel.Error,
        metadata: {
          orderId,
          orderData,
          skus: Object.keys(products),
          payment_method_id: `${distributionCenter?.commerceLayer.paymentMethod?.id}`,
          shipping_method_id: `${distributionCenter?.commerceLayer.shippingMethod?.id}`,
          error: { ...e },
        },
      })

      setHasError(true)
      setLoading(false)
    }

    return loading
  }

  return { loading, handleSubmit }
}

export default usePaymentHandler
