import styled from 'styled-components'
import { breakpoints } from '@ecommerce/shared'

export const Wrapper = styled.div`
  width: 100%;
  padding: 24px 16px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  margin-bottom: 16px;
  height: fit-content;
  @media (${breakpoints.tabletPortrait.min}) {
    margin-bottom: 24px;
  }
`
