import styled from 'styled-components'
import { toCssPrefix, breakpoints, hexToRGBA } from '@ecommerce/shared'

export const { toPrefix, cssPrefix } = toCssPrefix('AddressData__')

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
  .${cssPrefix} {
    &button {
      height: auto;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.red};
      display: flex;
      align-items: center;
      font-size: 13px;
      margin: 0 auto;
      svg {
        margin-right: 8px;
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .${cssPrefix} {
      &button {
        margin: 0;
      }
    }
  }
`

export const Data = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
  margin-bottom: 16px;
  @media (${breakpoints.desktop.min}) {
    margin: 0;
    flex-direction: row;
    align-items: center;
  }
`

export const Address = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  span {
    font-weight: 600;
  }
  @media (${breakpoints.desktop.min}) {
    padding: 0;
    margin: 0;
    border: none;
    font-size: 16px;
  }
`

export const Label = styled.div`
  font-weight: 600;
  font-size: 17px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  line-height: 25px;
  svg {
    margin-right: 8px;
  }
  @media (${breakpoints.desktop.min}) {
    font-size: 18px;
    margin: 0 8px 0 0;
  }
`
