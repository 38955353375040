import styled from 'styled-components'
import { breakpoints, hexToRGBA } from '@ecommerce/shared'
import { motion } from 'framer-motion'

export const Wrapper = styled.div`
  position: relative;
  display: flex;

  @media (${breakpoints.desktop.min}) {
    display: none;
  }
`
export const Background = styled.div<{ isOpen: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => hexToRGBA(theme.colors.black60, 0.5)};
  z-index: 99;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  top: 0;
  left: 0;
`
export const BottomSheetContainer = styled(motion.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0 16px;
  transition: all 0.5s;
  border-radius: 24px 24px 0px 0px;
  box-shadow: 0px -5px 24px 0px rgba(95, 111, 134, 0.08);
  z-index: 100;
  color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};

  .confirm-button {
    width: 100%;
  }

  @media (${breakpoints.tabletPortrait.min}) {
    padding: 0 40px;
  }
`

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 0 16px;
  align-items: center;
  svg {
    margin-right: 4px;
    font-weight: 600;
  }

  @media (${breakpoints.tabletPortrait.min}) {
    padding: 40px 0 24px;
  }
`
export const Content = styled.div`
  padding-bottom: 16px;
  max-height: 65vh;
  min-height: 40vh;
  overflow: scroll;
  @media (${breakpoints.tabletPortrait.min}) {
    padding-bottom: 24px;
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0 24px;
`

export const Title = styled.h2`
  font-size: 18px;
  margin: 0 0 8px;
  display: flex;
  align-items: center;
`
