import React from 'react'
import { Button, useLocation, PaymentTypes, ProductCart, OrderData, Market, Zone, Geocode } from '@ecommerce/shared'
import { Wrapper, toPrefix, Row, Title, DetailTotal } from './Detail.styled'
import PaymentHandlerButton from '../Confirm/PaymentHandlerButton'
import { useCheckout } from '../../../checkout.context'
import AlertMessage from '../../../../AlertMessage'

type Props = {
  setIsEdit: (isEdit: boolean) => void
  checkStockCallback: (hasValidStock: boolean, products?: ProductCart[]) => void
  disableButton: boolean
  orderData: OrderData
  currentMarket: Market
  currentZone: Zone
  geocode?: Geocode
  orderId: string
  selectedPaymentType: PaymentTypes
  refSessionId: string
  refGuestUser: {
    firstName?: string
    lastName?: string
  }
}
const Detail = ({
  setIsEdit,
  disableButton,
  checkStockCallback,
  orderData,
  currentMarket,
  currentZone,
  geocode,
  orderId,
  selectedPaymentType,
  refSessionId,
  refGuestUser,
}: Props) => {
  const { cartState, currentStep, setCurrentStep, selectPaymentError, selectTermsError } = useCheckout()
  const { toCurrency } = useLocation()

  return (
    <Wrapper>
      {selectPaymentError && (
        <AlertMessage type="error" className={toPrefix('alert-message')}>
          Selecciona un <span>medio de pago</span> para continuar.
        </AlertMessage>
      )}
      {selectTermsError && (
        <AlertMessage type="error" className={toPrefix('alert-message')}>
          Debes aceptar los <span>términos y condiciones</span> para continuar.
        </AlertMessage>
      )}
      {currentStep !== 3 && (
        <>
          <Row>
            <Title>Mi Carro</Title>
            <Button className={toPrefix('edit-button')} onClick={() => setIsEdit(true)} btnType="tertiary">
              Editar
            </Button>
          </Row>
          <DetailTotal>
            <span>Total a pagar</span>
            <span>{toCurrency(cartState.globalTotal)}</span>
          </DetailTotal>
        </>
      )}
      {currentStep === 2 && (
        <Button
          className={toPrefix('next-button')}
          onClick={() => setCurrentStep(3)}
          btnType="primary"
          disabled={disableButton}
          isDisabled={disableButton}
        >
          Continuar
        </Button>
      )}
      {currentStep === 3 && (
        <PaymentHandlerButton
          checkStockCallback={checkStockCallback}
          disabled={disableButton}
          orderData={orderData}
          currentCity={currentMarket}
          currentZone={currentZone}
          geocode={geocode}
          orderId={orderId}
          className={toPrefix('next-button')}
          buttonText={
            selectedPaymentType === PaymentTypes.WEBPAY ||
            selectedPaymentType === PaymentTypes.REDENLACE ||
            selectedPaymentType === PaymentTypes.CHEK ||
            selectedPaymentType === PaymentTypes.MACH
              ? 'Pagar'
              : 'Realizar pedido'
          }
          sessionId={refSessionId}
          guestUser={refGuestUser}
        />
      )}
    </Wrapper>
  )
}

export default Detail
