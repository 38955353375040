import React, { useState } from 'react'
import styled from 'styled-components'
import loadable from '@loadable/component'
import { navigate } from 'gatsby'
import {
  toCssPrefix,
  Button,
  useLocation,
  useShoppingCart,
  breakpoints,
  ProductCart,
  calculateCartTotals,
  toHash,
  hexToRGBA,
} from '@ecommerce/shared'
import secrets from '../../../../config/secrets'
import { TemplateCheckout } from '../../../../types/PgPages'
import { sendRemoveProductEvent } from '../../../../utils/events'

const NoStockProducts = loadable(() => import('../../../NoStockProducts'), { ssr: false })
const ModalWrapper = loadable(() => import('../../../NoStockProducts/ModalWrapper'), { ssr: false })

type Props = {
  products: ProductCart[] | undefined
  hideModalCallback: () => void
  templateData: TemplateCheckout
  afterRemoveProduct?: (product: ProductCart) => void
}

const { cssPrefix, toPrefix } = toCssPrefix('NoStockModal__')

const Wrapper = styled.div`
  .${cssPrefix} {
    &products {
      max-height: 300px;
    }
  }
`

const BottomResume = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 14px 36px;
  box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
  border-radius: ${({ theme }) => theme.borderRadius};

  .has-border-bottom {
    border-bottom: 2px solid ${({ theme }) => theme.colors.grey};
    padding-bottom: 14px;
  }

  .${cssPrefix} {
    &resume-title {
      text-align: center;
      color: ${({ theme }) => theme.colors.platinum80};
      font-size: 12px;
      margin-bottom: 0;
    }

    &resume-list {
      list-style: none;
      padding-left: 0;
      padding-top: 14px;
      margin: 0;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p,
        span {
          margin: 0 0 5px;
          font-size: 12px;
          color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};

          &.total {
            color: ${({ theme }) => theme.colors.black};
            font-weight: bold;
            font-size: 18px;
          }
        }
      }
    }

    &buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 14px;

      .delete-button {
        min-width: 150px;
        max-width: 260px;
        padding-left: 15px;
        padding-right: 15px;
        white-space: nowrap;
        margin-left: auto;
      }

      .back-button {
        color: ${({ theme }) => theme.colors.red};
        font-weight: bold;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        font-size: 17px;
      }

      @media screen and (${breakpoints.phoneLandscape.max}) {
        flex-direction: column-reverse;

        .delete-button {
          width: 80%;
          margin: 0 auto 15px;
          display: block;
        }
      }
    }
  }
`

const NoStockModal = ({ products = [], hideModalCallback, templateData, afterRemoveProduct }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const { toCurrency, isBolivia } = useLocation()
  const isBO = isBolivia()
  const { state: cartState, removeProductAsync } = useShoppingCart()
  const { globalTotal, shippingCost } = cartState

  // Cart sums
  const newSums = calculateCartTotals(toHash(products, 'skuCode'))
  const newSubTotal = globalTotal - newSums?.globalTotal
  const newTotal = newSubTotal + Number(shippingCost)
  const isSubtotalValid = newSubTotal >= Number(secrets.MIN_RAW_TOTAL)

  const onBackClick = async () => navigate('../?show_cart')

  const cleanStock = async () => {
    sendRemoveProductEvent(products, isBO)
    products.forEach((product) => {
      removeProductAsync(product)
      if (afterRemoveProduct) afterRemoveProduct(product)
    })
  }

  const onContinue = async () => {
    if (!isLoading) {
      setIsLoading(true)

      cleanStock()

      if (!isSubtotalValid) {
        await onBackClick()
      }

      hideModalCallback()
      setIsLoading(false)
    }
  }

  const {
    outOfStockModalTitle,
    outOfStockModalValidTotalText,
    outOfStockModalInvalidTotalText,
    outOfStockModalBackToCart,
    outOfStockModalBackInvalid,
    outOfStockModalContinueText,
    outOfStockModalResumeTitle,
  } = templateData

  const Bottom = () => (
    <BottomResume className={toPrefix('resume')}>
      <h4 className={toPrefix('resume-title has-border-bottom')}>{outOfStockModalResumeTitle}</h4>
      <ul className={toPrefix('resume-list has-border-bottom')}>
        <li>
          <p>Sub-total</p>
          <span>{toCurrency(newSubTotal)}</span>
        </li>
        <li>
          <p>Despacho</p>
          <span>{toCurrency(Number(shippingCost))}</span>
        </li>
        <li>
          <p className="total">Total</p>
          <span className="total">{toCurrency(newTotal)}</span>
        </li>
      </ul>
      <div className={toPrefix('buttons')}>
        {isSubtotalValid && (
          <button onClick={onBackClick} type="button" className="back-button">
            {outOfStockModalBackToCart}
          </button>
        )}
        <Button isLoading={isLoading} onClick={onContinue} className="delete-button">
          {isSubtotalValid ? outOfStockModalContinueText : outOfStockModalBackInvalid}
        </Button>
      </div>
    </BottomResume>
  )

  return (
    <Wrapper>
      <ModalWrapper
        title={outOfStockModalTitle}
        subtitle={isSubtotalValid ? outOfStockModalValidTotalText : outOfStockModalInvalidTotalText}
        bottom={<Bottom />}
      >
        <NoStockProducts className={toPrefix('products')} isBO={isBO} products={products} />
      </ModalWrapper>
    </Wrapper>
  )
}

export default NoStockModal
