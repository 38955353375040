import React, { useRef, useState, useEffect } from 'react'
import { ShoppingCartState } from '@ecommerce/shared/src/context/ShoppingCart/context'
import { Swiper, SwiperSlide } from 'swiper/react'
import { log, DistributionCenter, ProductCart } from '@ecommerce/shared'
import { getNavigatableProductsBySkus } from '../../../../../utils/algolia'
import { Icon } from '../../../../Icon/Icon'
import { toPrefix, SwiperWrapper, SwiperButton } from './SwiperCart.styled'
import { ChevronLeftIcon, ChevronRightIcon } from '../../../../Icons'

type Items = {
  sku: string
  image: string | null
  title: string
  quantity: number
  isGift: boolean
}

export const SwiperCart = ({
  cartState,
  currentDistributionCenter,
}: {
  cartState: ShoppingCartState
  currentDistributionCenter: DistributionCenter
}) => {
  const [isLoadingProducts, setIsLoadingProducts] = useState(false)
  const [gifts, setGifts] = useState<ProductCart[]>()
  const [items, setItems] = useState<Items[]>()

  const { byHash, giftLineItems = [] } = cartState

  const skus = Object.keys(byHash)
  const giftsSkus = giftLineItems.map((item) => item.sku).filter(Boolean)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingProducts(true)
        const algoliaProducts = await getNavigatableProductsBySkus(
          currentDistributionCenter.slug,
          giftsSkus as string[],
          {},
          currentDistributionCenter.slug,
          undefined,
          undefined,
          true,
        )

        const giftData = algoliaProducts.map((item) => {
          const giftPromotion = giftLineItems.find((e) => e.sku === item.skuCode.toString())
          return { ...item, quantity: giftPromotion?.discountQuantity ?? item.quantity, isGift: true }
        })

        setGifts(giftData)
      } catch (error) {
        log.error(error)
      } finally {
        setIsLoadingProducts(false)
      }
    }

    if (giftsSkus.length && !isLoadingProducts) fetchData()
  }, [giftLineItems])

  useEffect(() => {
    const byHashData = skus.map((sku) => {
      const data = byHash[sku]
      return {
        sku,
        image: data.image,
        title: data.title,
        quantity: data.quantity,
        isGift: data.isGift ?? false,
      }
    })

    const giftsData = gifts
      ? gifts.map((item) => {
          return {
            sku: item.skuCode,
            image: item.image,
            title: item.title,
            quantity: item.quantity,
            isGift: item.isGift ?? false,
          }
        })
      : []

    setItems([...byHashData, ...giftsData])
  }, [gifts, byHash])

  const [showButtonLeft, setShowButtonLeft] = useState(false)

  const buttonNextRef = useRef<HTMLDivElement | null>(null)
  const buttonPrevRef = useRef<HTMLDivElement | null>(null)

  const updateButtonsRef = () => {
    if (!buttonNextRef.current) {
      buttonNextRef.current = document.querySelector('.swiper-button-next')
    }

    if (!buttonPrevRef.current) {
      buttonPrevRef.current = document.querySelector('.swiper-button-prev')
    }
  }

  const swiperRight = () => {
    updateButtonsRef()
    if (!buttonNextRef.current) {
      return
    }
    buttonNextRef.current.click()
  }

  const swiperPrev = () => {
    updateButtonsRef()
    if (!buttonPrevRef.current) {
      return
    }
    buttonPrevRef.current.click()
  }

  const onSlideChange = () => {
    updateButtonsRef()

    if (!buttonNextRef.current || !buttonPrevRef.current) {
      return
    }

    if (buttonNextRef.current.classList.contains('swiper-button-disabled')) {
      setShowButtonLeft(true)
    }
    if (buttonPrevRef.current.classList.contains('swiper-button-disabled')) {
      setShowButtonLeft(false)
    }
  }

  return (
    <SwiperWrapper showButtonLeft={!!showButtonLeft}>
      <SwiperButton type="button" onClick={swiperPrev} showButtonLeft={!!showButtonLeft} showButton={!!showButtonLeft}>
        <ChevronLeftIcon />
      </SwiperButton>

      <Swiper className="swiper" navigation slidesPerView={4} onSlideChange={onSlideChange}>
        <>
          {items &&
            items.map((item) => {
              return (
                <SwiperSlide key={item.sku} className={toPrefix('swiper-slide')}>
                  <div className={toPrefix('slide-content')}>
                    <img src={item.image ?? ''} alt={item.title} />
                    {item.isGift ? (
                      <span className={toPrefix('slide-content-gift')}>
                        <Icon iconId="gift" strokeColor="white" size="10" />
                      </span>
                    ) : (
                      <span className={toPrefix('slide-content-amount')}>{item.quantity}</span>
                    )}
                  </div>
                </SwiperSlide>
              )
            })}
        </>
      </Swiper>

      {skus.length && skus.length > 4 && (
        <SwiperButton
          type="button"
          onClick={swiperRight}
          showButtonLeft={!!showButtonLeft}
          showButton={!showButtonLeft}
        >
          <ChevronRightIcon />
        </SwiperButton>
      )}
    </SwiperWrapper>
  )
}
