import styled from 'styled-components'
import { breakpoints, hexToRGBA, toCssPrefix } from '@ecommerce/shared'

export const { toPrefix, cssPrefix } = toCssPrefix('EmptyAddress__')

export const Wrapper = styled.div`
  color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
  .${cssPrefix} {
    &edit-button {
      color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
      font-size: 13px;
      margin-left: 16px;
      font-weight: 350;
      text-decoration: underline;
      padding: 0;
      background-color: transparent;
      height: auto;
    }
    &add-button {
      font-size: 13px;
      display: flex;
      align-items: center;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.red};
    }
  }
`

export const Label = styled.div`
  font-weight: 600;
  font-size: 17px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  line-height: 25px;
  svg {
    margin-right: 8px;
  }
  @media (${breakpoints.desktop.min}) {
    font-size: 18px;
  }
`

export const Location = styled.div`
  font-size: 16px;
  margin-bottom: 24px;
  font-weight: 325;
  span {
    color: ${({ theme }) => theme.colors.red};
    font-weight: 600;
  }
`

export const Content = styled.div`
  border-radius: 8px;
  background-color: #f7f9fc;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0;
  img {
    width: 80px;
  }
  p {
    font-size: 13px;
    font-weight: 350;
    text-align: center;
  }
  svg {
    margin-right: 8px;
  }
`
