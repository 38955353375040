import styled, { css } from 'styled-components'
import { breakpoints, Button } from '@ecommerce/shared'

export const Wrapper = styled.div`
  color: ${({ theme }) => theme.semanticColors.text.title.secondary};
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;

  @media screen and (${breakpoints.tabletPortrait.min}) {
    font-size: 18px;
    line-height: 22px;
  }
`

export const Description = styled.div`
  font-size: 13px;
  font-weight: 325;
  line-height: 18px;
  letter-spacing: -0.06px;
`

export const MainSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (${breakpoints.tabletPortrait.min}) {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .input {
    margin-top: -5px;
    min-width: 100%;
  }
  .recaptcha {
    width: 100%;
  }
  @media screen and (${breakpoints.desktop.min}) {
    .input {
      min-width: 260px;
    }
  }
`

export const StyledButton = styled(Button)`
  width: 99px;
`
export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  min-width: 311px;

  @media screen and (${breakpoints.tabletPortrait.min}) {
    min-width: 320px;
  }

  @media screen and (${breakpoints.desktop.min}) {
    //min-width: 383px;
  }
`

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 24px 32px 24px;
  background: ${({ theme }) => theme.semanticColors.background.emphasis};
  border-radius: 8px;
  gap: 16px;
  height: max-content;
`

export const CardTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > span {
    font-weight: bold;
  }
`

export const CardTitle = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
`

export const CardBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const CardCode = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: inherit;
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  line-height: 16px;
`

export const CommonStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 6px;
  padding-inline: 8px;
  border-radius: 4px;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  & > span:first-child {
    font-weight: bold;
  }
`

export const CardAppliedBalance = styled.div`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.semanticColors.text.display.primary};
  border: 1px solid ${({ theme }) => theme.colors.grey};
  ${CommonStyles}
`

export const CardCreditBalance = styled.div`
  background: ${({ theme }) => theme.colors.darkRed};
  color: ${({ theme }) => theme.colors.white};
  ${CommonStyles}
`

export const RemoveGiftCard = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  border: none;
  background: transparent;
  cursor: pointer;
  & > span:last-child {
    color: ${({ theme }) => theme.semanticColors.text.display.emphasis};
    font-size: 13px;
    line-height: normal;
    letter-spacing: -0.15px;
  }
`
