import { toCssPrefix, spinner, breakpoints, hexToRGBA } from '@ecommerce/shared'
import styled from 'styled-components'

export const { toPrefix, cssPrefix } = toCssPrefix('CouponCode__')

export const Wrapper = styled.div`
  position: relative;
  .${cssPrefix} {
    &text-field {
      input {
        padding-right: 90px;
        border: 1px solid ${({ theme }) => theme.colors.grey};
      }
    }
  }
`

export const ApplyButton = styled.button`
  color: ${({ theme }) => theme.colors.red};
  background: none;
  border: none;
  position: absolute;
  right: 20px;
  top: 42px;
  font-size: 13px;
  cursor: pointer;

  :disabled {
    cursor: default;
  }
  @media (${breakpoints.desktop.min}) {
    font-size: 15px;
  }
`

export const Loader = styled.div`
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  right: 42px;
  top: 44px;
  border-style: solid;
  border-width: 2px;
  border-color: ${({ theme }) => theme.colors.red};
  border-bottom-color: transparent;
  border-left-color: transparent;
  animation: ${spinner} 0.5s infinite linear;
`

export const Label = styled.div`
  font-size: 17px;
  font-weight: 600;
  color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
  @media (${breakpoints.desktop.min}) {
    font-size: 16px;
  }
`

export const InfoMessage = styled.div<{ type: 'success' | 'error' }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 12px;

  .success-message {
    color: ${({ theme, type }) => (type === 'success' ? theme.colors.green : theme.colors.error)};
    font-size: 12px;
    margin: 0;
  }

  svg {
    margin-right: 5px;
    fill: ${({ theme, type }) => (type === 'success' ? theme.colors.green : theme.colors.error)};
  }
`
