import styled from 'styled-components'
import { toCssPrefix, breakpoints, hexToRGBA } from '@ecommerce/shared'

export const { toPrefix, cssPrefix } = toCssPrefix('Summary__')

export const Wrapper = styled.div`
  color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
  .${cssPrefix} {
    &edit-button {
      font-weight: 300;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.red};
      height: auto;
      font-size: 12px;
    }
    &margin-16 {
      margin: 16px 0;
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      &edit-button {
        font-size: 15px;
      }
    }
  }
`

export const Detail = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.platinum80};
`

export const Discount = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.green};
`

export const Total = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  @media (${breakpoints.tabletPortrait.min}) {
    font-size: 18px;
  }
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const Title = styled.h2`
  font-size: 17px;
  font-weight: 600;
  margin: 0 0 8px;
  span {
    margin-left: 16px;
    font-size: 12px;
    font-weight: 325;
  }
  @media (${breakpoints.tabletPortrait.min}) {
    font-size: 24px;
  }
`
export const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.platinum20};
`
