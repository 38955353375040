import React, { useState, useEffect } from 'react'
import { BillingData, formatRut, useLocation, Checkbox, TextField, Button } from '@ecommerce/shared'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { getBillingSchema } from '../../../utils'
import { BillingFields, BillingInfoCard, CheckboxOption } from './SecurePayment.styled'

type Props = {
  onChange: (isShown: boolean) => void
  onSubmit: (data: BillingData) => void
  showForm?: boolean
  isExecutingPayment?: boolean
}

const BillingForm = ({ showForm = false, onChange, onSubmit, isExecutingPayment = false }: Props) => {
  const { register, watch, handleSubmit, formState, errors, setValue } = useForm<BillingData>({
    resolver: yupResolver(getBillingSchema()),
    reValidateMode: 'onChange',
    mode: 'all',
  })

  const [isShown, setIsShown] = useState(showForm)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [billingData, setBillingData] = useState<BillingData>()

  const fields = watch()

  // Texts
  const { textByCountry } = useLocation()
  const IDDocumentText = textByCountry('RUT', 'CI')

  useEffect(() => setIsShown(showForm), [showForm])

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target

    setIsShown(!checked)
    onChange(checked)
  }

  const handleBillingSubmit = (data: BillingData) => {
    setBillingData(data)
    setIsSubmitted(true)
    onSubmit(data)
  }

  const handleDNIChange = (dni: string) => {
    let value = dni
    value = dni.length === 0 || dni === '-' || dni === ' ' ? '' : formatRut(dni)

    return setValue('billing_user_id', value)
  }

  return (
    <BillingFields className="BillingFields">
      <CheckboxOption className="BillingFields__CheckboxOption">
        <Checkbox
          data-testid="checkbox-billing-data"
          defaultChecked={!showForm}
          value="billing_data"
          name="billing_data"
          onChange={handleCheck}
          disabled={isExecutingPayment}
        />
        <span>{`Usar mis datos para  ${textByCountry('boleta', 'factura')}`}</span>
      </CheckboxOption>

      {isShown ? (
        <>
          {!isSubmitted ? (
            <div className="BillingFields__form">
              <p className="BillingFields__form-title">{`Datos asociados a ${textByCountry('boleta', 'factura')}`}</p>
              <TextField
                data-testid="billing-first-name"
                ref={register}
                name="billing_first_name"
                className="BillingFields__input"
                label="Nombre"
                defaultValue={fields?.billing_first_name ?? billingData?.billing_first_name}
                status={!errors?.billing_first_name ? undefined : 'error'}
                errorMessage={errors?.billing_first_name?.message}
                disabled={isExecutingPayment}
              />
              <TextField
                data-testid="billing-last-name"
                ref={register}
                name="billing_last_name"
                className="BillingFields__input"
                label="Apellido"
                defaultValue={fields?.billing_last_name ?? billingData?.billing_last_name}
                status={!errors?.billing_last_name ? undefined : 'error'}
                errorMessage={errors?.billing_last_name?.message}
                disabled={isExecutingPayment}
              />

              <TextField
                data-testid="billing-address"
                ref={register}
                name="billing_address"
                className="BillingFields__input"
                label={`Dirección (Calle, Nº, ${textByCountry('Comuna', 'Municipio')})`}
                defaultValue={fields?.billing_address ?? billingData?.billing_address}
                status={!errors?.billing_address ? undefined : 'error'}
                errorMessage={errors?.billing_address?.message}
                disabled={isExecutingPayment}
              />
              <TextField
                data-testid="billing_user"
                ref={register({
                  validate: (value) => value === '1' || 'error message',
                })}
                onChange={({ target: { value } }) => handleDNIChange(value)}
                name="billing_user_id"
                className="BillingFields__input"
                label={IDDocumentText}
                defaultValue={fields?.billing_user_id ?? billingData?.billing_user_id}
                status={!errors?.billing_user_id ? undefined : 'error'}
                errorMessage={errors?.billing_user_id?.message}
                maxLength={20}
                disabled={isExecutingPayment}
              />
              <Button
                data-testid="billing-confirm"
                onClick={handleSubmit(handleBillingSubmit)}
                className={`SaveBillingButton ${!formState.isValid ? 'is-invalid' : 'is-valid'}`}
                isDisabled={isExecutingPayment || !formState.isValid}
                disabled={isExecutingPayment || !formState.isValid}
              >
                Continuar
              </Button>
            </div>
          ) : (
            <BillingInfoCard>
              <p className="BillingFields__form-title">{`Datos asociados a ${textByCountry('boleta', 'factura')}`}</p>
              <p className="billing-form-info-text">{`- ${billingData?.billing_first_name} ${billingData?.billing_last_name}`}</p>
              <p className="billing-form-info-text">{`- ${billingData?.billing_address}`}</p>
              <p className="billing-form-info-text">{`- ${IDDocumentText}: ${billingData?.billing_user_id}`}</p>
              <button
                type="button"
                title="Editar"
                onClick={() => setIsSubmitted(false)}
                className={`BillingInfoCard__edit-button${isExecutingPayment ? ' is-disabled' : ''}`}
                disabled={isExecutingPayment}
              >
                Editar
              </button>
            </BillingInfoCard>
          )}
        </>
      ) : null}
    </BillingFields>
  )
}

export default BillingForm
