import React from 'react'
import { Market, Button, Image, FormattedAddress } from '@ecommerce/shared'
import { Wrapper, Location, Label, toPrefix, Content } from './EmptyAddress.styled'
import { Icon } from '../../../../Icon/Icon'

type Props = {
  currentMarket: Market
  handleOpenAddressForm: (address?: FormattedAddress) => void
  setIsChangingMarket: (value: boolean) => void
}
const EmptyAddress = ({ currentMarket, handleOpenAddressForm, setIsChangingMarket }: Props) => {
  return (
    <Wrapper>
      <Label>
        <Icon iconId="truck" size="20" />
        Mi Entrega
      </Label>
      <Location>
        Estás en <span>{currentMarket.name}</span>
        <Button className={toPrefix('edit-button')} btnType="tertiary" onClick={() => setIsChangingMarket(true)}>
          Cambiar
        </Button>
      </Location>
      <Content>
        <Image
          src="https://images.ctfassets.net/16npdkkoi5mj/11CK87SIApMdFVvUCBn7zp/6d688849669195424f5ead55c162fbea/ubicacion.png"
          alt="location-icon"
          preload={false}
          params={{ w: 90 }}
        />
        <p>No tienes direcciones registradas en esta ciudad</p>
        <Button className={toPrefix('add-button')} btnType="tertiary" onClick={() => handleOpenAddressForm()}>
          <Icon iconId="addcircle_outline" size="20" />
          Agregar nueva dirección
        </Button>
      </Content>
    </Wrapper>
  )
}

export default EmptyAddress
