import React, { useEffect, useState } from 'react'
import { FormattedAddress, Button } from '@ecommerce/shared'
import { Wrapper, Data, toPrefix, Address, Label } from './AddressData.styled'
import { Icon } from '../../../../Icon/Icon'

type Props = {
  selectedAddress: FormattedAddress
  handleChangeAddress: () => void
}

const AddressData = ({ selectedAddress, handleChangeAddress }: Props) => {
  const [shippingAddress, setShippingAddress] = useState<string[]>([])

  useEffect(() => {
    const address = selectedAddress?.shipping_address?.split(',')
    if (address.length > 1) address.pop()
    setShippingAddress(address)
  }, [])

  return (
    <Wrapper>
      <Data>
        <Label>
          <Icon iconId="truck" size="20" />
          Mi Entrega
        </Label>
        <Address>
          {shippingAddress.map((item) => `${item},`)}
          <span> {selectedAddress.marketName}</span>
        </Address>
      </Data>
      <Button btnType="tertiary" className={toPrefix('button')} onClick={handleChangeAddress}>
        <Icon iconId="edit" size="20" />
        Cambiar dirección
      </Button>
    </Wrapper>
  )
}

export default AddressData
