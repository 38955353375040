import React from 'react'
import { Wrapper } from './Card.styled'

type Props = {
  children: React.ReactNode
  className?: string
}
const Card = ({ children, className }: Props) => {
  return <Wrapper className={className}>{children}</Wrapper>
}

export default Card
