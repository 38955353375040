import React, { useState, useEffect } from 'react'
import { SelectOption, Select, AvailableDate, getAvailableDates, toWeekDay } from '@ecommerce/shared'
import { Wrapper, Label, Description, ErrorMessage } from './DeliveryDate.styled'
import { Icon } from '../../../../Icon/Icon'
import secrets from '../../../../../config/secrets'
import { useCheckout } from '../../../checkout.context'

type Props = {
  marketId: number
  zoneId: number
  selectedCommune?: string
  onChange: (date: string) => void
}
const customCommunesSettings = [
  { name: 'Dichato', allowedDays: [6] },
  { name: 'Pingueral', allowedDays: [6] },
  { name: 'Mantagua', allowedDays: [2, 5] },
  { name: 'Placilla y Curauma', allowedDays: [1, 3, 4, 6] },
]

const DeliveryDate = ({ marketId, zoneId, selectedCommune, onChange }: Props) => {
  const { COUNTRY: country } = secrets

  const { isExecutingPayment, setDeliveryDate, deliveryDate } = useCheckout()

  const [hasError, setHasError] = useState(false)
  const [deliveryDates, setDeliveryDates] = useState<SelectOption[]>([])
  const [showEmptyDatesMessage, setShowEmptyDatesMessage] = useState(false)

  const filterDates = (dates: AvailableDate[]): SelectOption[] => {
    const communeSettings = customCommunesSettings.find(({ name }) => name === selectedCommune)

    if (!communeSettings) return dates

    return dates.filter(({ weekDay }) => communeSettings.allowedDays.includes(weekDay))
  }

  // Fetch available dates
  const fetchDates = async () => {
    try {
      // Clear errors
      setHasError(false)
      setShowEmptyDatesMessage(false)

      // Fetch dates
      const availableDates = filterDates(await getAvailableDates(marketId, zoneId, country))

      if (availableDates.length === 0) setShowEmptyDatesMessage(true)

      setDeliveryDate(availableDates[0].value)
      setDeliveryDates(availableDates)
    } catch {
      setHasError(true)
    }
  }

  useEffect(() => {
    if (deliveryDate) onChange(deliveryDate)
  }, [deliveryDate])

  useEffect(() => {
    fetchDates()
  }, [selectedCommune])

  return (
    <Wrapper>
      <Label>
        <Icon iconId="calendar" size="20" />
        Fecha de despacho
      </Label>
      <Description>Hemos seleccionado la fecha de despacho más próxima, puedes seleccionar otra fecha.</Description>
      {hasError && (
        <ErrorMessage>
          <p>
            Ha habido un error obteniendo las fechas disponibles, por favor
            <button type="button" onClick={fetchDates}>
              intenta de nuevo
            </button>
          </p>
        </ErrorMessage>
      )}
      <Select
        key={selectedCommune}
        value={deliveryDate ? toWeekDay(deliveryDate).label : ''}
        className="Select__"
        name="date_select"
        label=""
        dataTest="checkout-date-select"
        options={deliveryDates}
        onSelect={(val) => {
          setDeliveryDate(`${val}`)
        }}
        placeholder="Seleccionar el día de despacho"
        errorMessage={showEmptyDatesMessage ? 'No hay fechas disponibles para tu ciudad' : ''}
        disabled={isExecutingPayment}
      />
    </Wrapper>
  )
}

export default DeliveryDate
