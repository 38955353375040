import React from 'react'
import { skeletonAnimation } from '@ecommerce/shared'
import styled from 'styled-components'

const StyledSection = styled.div`
  width: 100%;
  height: 485px;
  border-radius: ${({ theme }) => theme.borderRadius};
`

const SkeletonItem = styled.div<{ width?: number; height?: number; margin?: string }>`
  background: ${({ theme }) => theme.colors.white};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => (height ? `${height}px` : '40px')};
  opacity: 1;
  animation: ${skeletonAnimation} 1.5s linear infinite;
  border-radius: 4px;
  margin-bottom: 16px;
`

const SkeletonScreen = () => (
  <StyledSection>
    <SkeletonItem height={70} />
    <SkeletonItem height={187} />
  </StyledSection>
)

export default SkeletonScreen
