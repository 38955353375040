import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import {
  BillingData,
  calculateCartTotals,
  Country,
  CustomerGroups,
  DistributionCenter,
  Geocode,
  getAuth,
  getAuthName,
  getBirthdate,
  getCustomerEmail,
  getCustomerGroup,
  getDni,
  getGuest,
  getOrderCountToday,
  getOrderId,
  getOwnerId,
  getStoredDistributionCenter,
  Market,
  Order,
  OrderData,
  PaymentTypes,
  ProductCart,
  updateShippingAddress,
  useLocation,
  useShoppingCart,
  Zone,
} from '@ecommerce/shared'
import { yupResolver } from '@hookform/resolvers'
import { v4 as uuidv4 } from 'uuid'
import { FormProvider, useForm } from 'react-hook-form'
import { navigate } from 'gatsby'
import { TemplateCheckout } from '../../types/PgPages'
import Navbar from './components/Navbar'
import { Container, toPrefix, Wrapper } from './Checkout.styled'
import { IconId } from '../Icon/Icon'
import MyData from './components/MyData'
import OrderDetail from './components/OrderDetail'
import secrets from '../../config/secrets'
import { ErrorLevel, sendMessageToSentry } from '../../utils/sentry'
import MyDelivery from './components/MyDelivery'
import { CheckoutContext } from './checkout.context'
import { loggedCheckoutSchema, orderSchema } from './utils'
import NoStockModal from './components/NoStockModal'
import PaymentMethods from './components/PaymentMethods'
import { tagManagerCheckoutActionFields } from '../../utils/gtm'

interface Props {
  currentDistributionCenter: DistributionCenter
  currentMarket: Market
  currentZone: Zone
  templateData: TemplateCheckout
}

export type UserInformation = {
  ownerId: string | null
  firstName: string | null
  lastName: string | null
  dni: string | null
  email: string | null
  birthdate: string | null
  isAuth: boolean
  customerGroup?: CustomerGroups
}

export const getLoggedCustomerOrder = (): UserInformation => {
  return {
    ...getAuthName(),
    isAuth: getAuth(),
    ownerId: getOwnerId(),
    email: getCustomerEmail(),
    birthdate: getBirthdate(),
    dni: getDni(),
    customerGroup: getCustomerGroup(),
  }
}

export type stepProps = {
  id: number
  title: string
  icon: IconId
  component: JSX.Element
}

const minSubtotal = Number(secrets.MIN_RAW_TOTAL)

const CheckoutWrapper = ({ currentMarket, templateData, currentZone, currentDistributionCenter }: Props) => {
  const {
    cartState,
    setCartState,
    addressIsValidated,
    editingAddress,
    isAddingAddress,
    isLoadingCoupon,
    selectedAddress,
    deliveryDate,
    setIsLoadingCoupon,
    setShowAddress,
    setUseDifferentBillingData,
    useDifferentBillingData,
    outOfStockProducts,
    setOutOfStockProducts,
    isQRSelected,
    sendGTMCheckoutData,
    getCartStateFromCommerceLayer,
    districtState,
    setDistrictState,
    orderTotal,
    currentStep,
    isSelectingAddress,
    giftCard,
  } = useContext(CheckoutContext)

  const {
    state: { country },
    isBolivia,
  } = useLocation()
  const { removeProductAsync } = useShoppingCart()

  const refOrderId = useRef(getOrderId() || '')
  const refSessionId = useRef(uuidv4())
  const refGuestUser = useRef(getGuest())

  const [orderCustomer, setOrderCustomer] = useState<UserInformation>(getLoggedCustomerOrder())

  const [isLoadingUserData, setIsLoadingUserData] = useState(orderCustomer.isAuth ?? false)

  const [billingData, setBillingData] = useState<BillingData | null>(null)

  const [geocode, setGeocode] = useState<Geocode>()

  // Payment type
  const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentTypes>(
    isBolivia() ? PaymentTypes.REDENLACE : PaymentTypes.WEBPAY,
  )

  const [isUserBlocked, setIsUserBlocked] = useState(false)
  const [orderCount, setOrderCount] = useState(0)

  const { firstName, lastName, isAuth, customerGroup, ownerId } = orderCustomer

  const validationSchema = !isAuth || isAddingAddress || editingAddress !== null ? orderSchema : loggedCheckoutSchema

  const methods = useForm<OrderData>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      marketing: true,
    },
  })

  const { register, watch, setValue, reset, errors, setError } = methods

  const watchFields = watch()

  const { addressId } = watchFields

  const orderData = {
    ...watchFields,
    payment_type: selectedPaymentType,
    shipping_address: watchFields?.shipping_address ?? selectedAddress?.shipping_address,
    shipping_name: watchFields?.shipping_name ?? selectedAddress?.shipping_name,
    shipping_number: watchFields?.shipping_number ?? selectedAddress?.shipping_number,
    shipping_phone: watchFields?.shipping_phone ?? selectedAddress?.shipping_phone,
    shipping_instructions: watchFields?.shipping_instructions ?? selectedAddress?.shipping_instructions,
    isQRSelected,
  }

  const canPayLogged =
    Object.keys(errors).length === 0 &&
    watchFields.addressId !== undefined &&
    editingAddress === null &&
    !isAddingAddress
  const canPay = !isAuth ? addressIsValidated && Object.keys(errors).length === 0 && geocode : canPayLogged

  const hasBillingData = useDifferentBillingData ? billingData !== null : true

  const disabledWhenUseGiftCard = (): boolean => {
    if (orderData.payment_type === 'gift-card') return false
    if (giftCard && orderTotal > 0 && orderTotal < minSubtotal) return false
    return orderTotal < minSubtotal
  }

  const disableButton =
    currentStep === 3
      ? !canPay || isLoadingCoupon || !hasBillingData || disabledWhenUseGiftCard()
      : !selectedAddress || !deliveryDate || isAddingAddress || isSelectingAddress

  const getOrderCount = useCallback(
    async (customerId: string, customerCountry: Country) => {
      const count = await getOrderCountToday({ customerId, country: customerCountry })
      setOrderCount(count)
    },
    [getOrderCountToday],
  )

  useEffect(() => {
    if (isBolivia()) setSelectedPaymentType(PaymentTypes.REDENLACE)
  }, [isBolivia()])

  // Register fields
  register({ name: 'date' })
  register({ name: 'payment' })
  register({ name: 'terms' })
  register({ name: 'marketing' })
  register({ name: 'shipping_address' })
  register({ name: 'shipping_name' })
  register({ name: 'shipping_number' })
  register({ name: 'shipping_phone' })
  register({ name: 'shipping_instructions' })
  register({ name: 'addressId' })

  useEffect(() => {
    try {
      // Redirect if !orderId
      if (!refOrderId.current) navigate('/404', { state: {} })

      // Get clayer order
      setIsLoadingCoupon(true)
      getCartStateFromCommerceLayer(setCartState)
    } catch (e) {
      sendMessageToSentry({
        message: 'Checkout: init Error',
        page: currentMarket ? `/checkout` : 'checkout',
        level: ErrorLevel.Critical,
        metadata: {
          error: e?.response ?? e?.message ?? e,
        },
      })
    }
  }, [])

  useEffect(() => {
    refGuestUser.current = { firstName: firstName || '', lastName: lastName || '' }
  }, [firstName, lastName])

  // Handle input changes - NOT THE ADDRESS FORM
  useEffect(() => {
    if (districtState?.selectedZone !== selectedAddress?.zoneName) {
      const zone = currentMarket.zones.filter((z) => z.id === selectedAddress?.zoneId)[0]
      if (zone)
        setDistrictState((prev) => ({
          ...prev,
          selectedZone: zone,
        }))
    }
  }, [selectedAddress])

  useEffect(() => {
    if (orderCustomer.email) {
      setIsLoadingUserData(false)
    }
  }, [orderCustomer])

  const handleChange = (name: keyof Order, value: string | boolean) => {
    setValue(name, value, { shouldValidate: true })
  }

  useEffect(() => {
    reset({ ...orderData })
    window.scrollTo(0, 0)
    if (currentStep === 3) {
      reset({
        ...watchFields,
        terms: false,
        marketing: true,
        payment: undefined,
      })
      setError('terms', { message: '' })
    }
  }, [currentStep])

  useEffect(() => {
    if (giftCard) {
      if (orderTotal === 0) {
        setSelectedPaymentType(PaymentTypes.GIFT_CARD)
        setValue('payment', PaymentTypes.GIFT_CARD, { shouldValidate: true })
      } else if (orderTotal > 0) {
        switch (selectedPaymentType) {
          case 'chek':
            setSelectedPaymentType(PaymentTypes.GIFT_CARD_CHEK)
            break
          case 'mach':
            setSelectedPaymentType(PaymentTypes.GIFT_CARD_MACH)
            break
          case 'webpay':
            setSelectedPaymentType(PaymentTypes.GIFT_CARD_WEBPAY)
            break
          case 'flowpay':
            setSelectedPaymentType(PaymentTypes.GIFT_CARD_FLOWPAY)
            break
          default:
        }
      }
    }
  }, [giftCard, selectedPaymentType])

  const setShippingAddress = async () => {
    const shippingMethod = getStoredDistributionCenter()?.commerceLayer.shippingMethod.id
    if (addressId && shippingMethod) {
      await updateShippingAddress(refOrderId.current, addressId, shippingMethod)
    }
  }

  useEffect(() => {
    setShippingAddress()
  }, [addressId])

  // It gets number of orders when user has customer group
  useEffect(() => {
    if (
      ownerId &&
      customerGroup &&
      (customerGroup === CustomerGroups.MAXIMO1 || customerGroup === CustomerGroups.MAXIMO3)
    ) {
      getOrderCount(ownerId, country)
    }
  }, [ownerId, customerGroup, country])

  // It validates if the user is blocked in commerce layer
  useEffect(() => {
    if (customerGroup === CustomerGroups.BLOQUEADO) {
      setIsUserBlocked(true)
    }
    if (customerGroup === CustomerGroups.MAXIMO1 && orderCount >= 1) {
      setIsUserBlocked(true)
    }
    if (customerGroup === CustomerGroups.MAXIMO3 && orderCount >= 3) {
      setIsUserBlocked(true)
    }
  }, [customerGroup, orderCount])

  if (useDifferentBillingData) {
    orderData.billing = billingData
  }

  const handleBillingChange = (isShown: boolean) => {
    setUseDifferentBillingData(!isShown)
  }

  const steps: stepProps[] = [
    {
      id: 1,
      title: 'Mis Datos',
      icon: 'user',
      component: (
        <MyData
          key="my-data"
          refOrderId={refOrderId}
          setOrderCustomer={setOrderCustomer}
          orderCustomer={orderCustomer}
          setIsLoadingUserData={setIsLoadingUserData}
          isLoadingUserData={isLoadingUserData}
          setShowAddress={setShowAddress}
        />
      ),
    },
    {
      id: 2,
      title: 'Mi Entrega',
      icon: 'truck',
      component: (
        <MyDelivery
          key="my-delivery"
          currentZone={currentZone}
          currentMarket={currentMarket}
          orderCustomer={orderCustomer}
          setIsLoadingUserData={setIsLoadingUserData}
          isLoadingUserData={isLoadingUserData}
          geocode={geocode}
          setGeocode={setGeocode}
          handleChange={handleChange}
        />
      ),
    },
    {
      id: 3,
      title: 'Pago',
      icon: 'creditcard',
      component: (
        <PaymentMethods
          key="payment-methods"
          templateData={templateData}
          onPaymentTypeChange={setSelectedPaymentType}
          onBillingSubmit={setBillingData}
          onBillingChange={handleBillingChange}
          orderId={refOrderId.current}
          onChange={(methodID, label) => {
            handleChange('payment', methodID)
            sendGTMCheckoutData(tagManagerCheckoutActionFields.paymentMethod(label ?? 'Tarjeta de crédito'))
          }}
        />
      ),
    },
  ]

  const hideStockModal = () => setOutOfStockProducts([])

  const onStockCheck = (isValidStock: boolean, products?: ProductCart[]) => {
    if (!isValidStock && products && products.length !== 0) {
      setOutOfStockProducts(products)
    }
  }

  const afterRemoveProduct = (product: ProductCart) => {
    const { [product.skuCode]: removedProduct, ...newByHash } = cartState.byHash
    if (removedProduct) {
      const newTotals = calculateCartTotals(newByHash)
      setCartState({ ...cartState, ...newTotals, byHash: newByHash })
    }
  }

  return (
    <FormProvider {...methods}>
      <Wrapper>
        {outOfStockProducts.length !== 0 && (
          <NoStockModal
            templateData={templateData}
            hideModalCallback={hideStockModal}
            products={outOfStockProducts}
            afterRemoveProduct={afterRemoveProduct}
          />
        )}
        <Navbar currentStep={currentStep} steps={steps} />
        <Container>
          <div className={toPrefix('content')}>{steps.map((step) => step.id === currentStep && step.component)}</div>
          <OrderDetail
            disableButton={disableButton}
            checkStockCallback={onStockCheck}
            handleChange={handleChange}
            orderData={orderData}
            currentDistributionCenter={currentDistributionCenter}
            orderId={refOrderId.current}
            refSessionId={refSessionId.current}
            refGuestUser={refGuestUser.current}
            onRemoveProduct={async (params) => {
              await removeProductAsync(params, refOrderId.current ?? '', () => {
                getCartStateFromCommerceLayer(setCartState)
              })
            }}
            onUpdateCartState={() => {
              getCartStateFromCommerceLayer(setCartState)
            }}
            currentMarket={currentMarket}
            currentZone={currentZone}
            geocode={geocode}
            selectedPaymentType={selectedPaymentType}
          />
        </Container>
      </Wrapper>
    </FormProvider>
  )
}

export default CheckoutWrapper
