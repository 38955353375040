import styled from 'styled-components'
import { breakpoints, hexToRGBA, toCssPrefix } from '@ecommerce/shared'

export const { toPrefix, cssPrefix } = toCssPrefix('CheckoutWrapper__')

export const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.grey40};
  min-height: calc(100vh - 326px);
`
export const Container = styled.div`
  width: 100%;
  max-width: 1320px;
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  column-gap: 0;
  margin: 0 auto;
  justify-content: center;
  .${cssPrefix} {
    &content {
      width: 100%;
    }
    &order-detail {
      width: 100%;
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    padding: 48px 40px;
  }
  @media (${breakpoints.desktop.min}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .${cssPrefix} {
      &content {
        width: 66%;
      }
      &order-detail {
        width: 32.1%;
        margin-left: 24px;
      }
    }
  }
`
export const BackButton = styled.button`
  position: absolute;
  font-size: 15px;
  color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
  top: 40px;
  left: 90px;
  background: none;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0;

  svg {
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
    margin-right: 6px;
  }

  @media screen and (${breakpoints.tabletLandscape.max}) {
    position: fixed;
    top: 24px;
    left: 25px;
    z-index: 4;
    font-size: 0;

    svg {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`

export const ContentEdit = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
