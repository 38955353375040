import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { graphql, navigate } from 'gatsby'
import { log, WindowWithMaps, getStoredMarket, getStoredZone, useShoppingCart, useAuth } from '@ecommerce/shared'
import secrets from '../config/secrets'
import withPageTransition from '../components/withPageTransition'
import { PgPageProps } from '../types/PgPages'
import useTimeoutModal from '../components/Checkout/hooks/useTimeoutModal'
import Layout from '../components/Layout/LayoutStaticPage'
import { sendPageViewEvent } from '../utils/events'
import CheckoutWrapper from '../components/Checkout'
import { BackButton } from '../components/Checkout/Checkout.styled'
import { Icon } from '../components/Icon/Icon'
import { CheckoutProvider } from '../components/Checkout/checkout.context'

declare const window: WindowWithMaps

const CheckoutPage = ({
  data,
  pageContext: { currentMarket, currentZone, currentDistributionCenter },
  location: { state: locationState },
}: PgPageProps) => {
  const {
    inactivityAlertTime,
    inactivityRedirectTime,
    inactivityModalText,
    inactivityModalButtonText,
  } = data.allContentfulPgPage.edges[0].node.template
  const templateData = data.allContentfulPgPage.edges[0].node.template
  const storedMarket = getStoredMarket() ?? currentMarket
  const storedZone = getStoredZone() ?? currentZone

  const { getConnectifCart } = useShoppingCart()
  const { getEntityInfo } = useAuth()

  useEffect(() => {
    const entityInfo = getEntityInfo()
    const cart = getConnectifCart()
    sendPageViewEvent({ cart, entityInfo, title: 'Checkout' })
  }, [])

  const { renderTimeoutModal } = useTimeoutModal({
    inactivityAlertTime,
    inactivityRedirectTime,
    redirectSlug: `/`,
    alertContent: {
      text: inactivityModalText,
      buttonText: inactivityModalButtonText,
    },
  })

  const isFromRedirect = () => {
    const navigationTiming: Partial<PerformanceNavigationTiming> = performance?.getEntriesByType('navigation')[0]
    if (navigationTiming?.type) {
      return navigationTiming.type === 'reload'
    }

    const performanceNavigation = window?.performance?.navigation
    if (performanceNavigation?.type) {
      return performanceNavigation?.type === performance.navigation.TYPE_RELOAD
    }

    return false
  }
  const beforeUnload = () => {
    // Set maps api to null to avoid unwanted side effects when navigating between pages
    if (window.google?.maps) {
      window.google.maps = null
    }

    if (!isFromRedirect()) {
      // Clear location state referrer to avoid people from accessing the page directly
      window.history.replaceState(null, '')
    }
  }

  useEffect(() => {
    if ((!locationState || !locationState?.referrer) && !isFromRedirect()) {
      log.debug('SHOULD NAVIGATE CHECKOUT 2', {
        locationState,
      })
      navigate(`/`)
    }

    window.addEventListener('beforeunload', beforeUnload)

    return () => {
      beforeUnload()
      window.removeEventListener('beforeunload', beforeUnload)
    }
  }, [])

  return (
    <Layout title={data.allContentfulPgPage.edges[0].node.title}>
      <BackButton className="back-button" onClick={() => navigate(-1)}>
        <Icon iconId="arrow_left" />
        Volver
      </BackButton>
      <Helmet>
        <script src={secrets.GATSBY_GOOGLE_MAPS_URL} />
      </Helmet>
      {renderTimeoutModal()}
      <CheckoutProvider>
        <CheckoutWrapper
          templateData={templateData}
          currentDistributionCenter={currentDistributionCenter}
          currentMarket={storedMarket}
          currentZone={storedZone}
        />
      </CheckoutProvider>
    </Layout>
  )
}

export default withPageTransition(CheckoutPage)

export const query = graphql`
  query CheckoutPageQuery($checkoutId: String!) {
    allContentfulPgPage(filter: { contentful_id: { eq: $checkoutId } }) {
      edges {
        node {
          id
          title
          template {
            ... on ContentfulTmCheckout {
              id
              onDeliveryPaymentText
              qrPaymentText
              privacyPolicyLink
              paymentSourceLink
              inactivityModalText
              inactivityModalButtonText
              inactivityAlertTime
              inactivityRedirectTime
              outOfStockModalTitle
              outOfStockModalValidTotalText
              outOfStockModalInvalidTotalText
              outOfStockModalContinueText
              outOfStockModalResumeTitle
              outOfStockModalBackToCart
              outOfStockModalBackInvalid
              paymentSourceLogo {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
