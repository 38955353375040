import React, { useState, useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import {
  Button,
  TextField,
  useLocation,
  FormattedAddress,
  Market,
  InfoMessage,
  useMap,
  OnMapChange,
  useGooglePlaceAutocomplete,
  DistrictProps,
  Coordinates,
  useResolution,
  Select,
  parseMarketData,
  Geocode,
  Zone,
  Image,
  getStoredDistributionCenter,
} from '@ecommerce/shared'
import {
  Wrapper,
  ContentButtons,
  toPrefix,
  Row,
  Title,
  Location,
  MapWrapper,
  ResultsWrapper,
} from './AddressForm.styled'
import { Icon } from '../../../../Icon/Icon'
import secrets from '../../../../../config/secrets'

export type DistrictOption = {
  market: string
  locations: Zone[]
}

type Prediction = google.maps.places.AutocompletePrediction

type Props = {
  handleAddressBack: () => void
  editingAddress: FormattedAddress | null
  currentMarket: Market
  districtState: DistrictProps
  shouldClear?: boolean
  setIsChangingMarket: (value: boolean) => void
  setIsSelectingAddress: (value: boolean) => void
  handleAddressSubmit: () => void
  onMapClick: (value: string, geocode: Coordinates) => void
  onSelect: (val: string, geocode?: Geocode) => void
  onInputChange: (val: string, hasClickedMap?: boolean) => void
  isAuth?: boolean
  addressIsValid: boolean
}

const mapPin =
  'https://images.ctfassets.net/16npdkkoi5mj/5QlwfaRDx04LOqztiU8Kjz/c603f995534af57fe75f33446af6dc5c/location.svg?h=250'

const AddressForm = ({
  handleAddressBack,
  setIsChangingMarket,
  editingAddress,
  currentMarket,
  districtState,
  shouldClear,
  setIsSelectingAddress,
  handleAddressSubmit,
  onMapClick,
  onSelect,
  onInputChange,
  isAuth = false,
  addressIsValid,
}: Props) => {
  const currentDistributionCenter = getStoredDistributionCenter()

  const { isDesktop } = useResolution()

  const methods = useFormContext()

  const resultsContainer = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const mapRef = useRef<HTMLDivElement>(null)

  const [searchMarket, setSearchMarket] = useState(districtState.state.selectedZone?.name ?? currentMarket.slug)
  const [shouldClearInput, setShouldClearInput] = useState(shouldClear)

  const { COUNTRY } = secrets

  const { errors, register } = methods

  const {
    setQuery,
    setSelected,
    ready,
    suggestions,
    loadingSuggestions,
    geocode,
    loadingGeocode,
  } = useGooglePlaceAutocomplete({
    city: searchMarket,
    countryCode: COUNTRY,
    attrContainer: resultsContainer.current,
  })

  const [districtSettings, setDistrictSettings] = useState<{ hasMultiple: boolean; data: DistrictOption | null }>({
    hasMultiple: false,
    data: null,
  })

  const shippingAddressErrorMessage =
    errors?.shipping_address?.message ?? (geocode === undefined && 'Selecciona una dirección válida')

  const [shippingAddress, setShippingAddress] = useState(editingAddress?.shipping_address ?? '')
  const [isSelected, setIsSelected] = useState(false)
  const [inputIsChanging, setInputIsChanging] = useState(false)
  const [typingTimeout, setTipingTimeout] = useState(0)
  const [showOptions, setShowOptions] = useState(false)

  // THIS NEEDS IMPROVEMENT
  const [hideErrorMessage, setHideErrorMessage] = useState(true)
  const [showError, setShowError] = useState(false)
  const [mapHasBeenClicked, setMapHasBeenClicked] = useState(false)

  // Map settings
  const onMapChange: OnMapChange = ({ val, lat, lng }) => {
    setShippingAddress(val)
    setQuery(val, false)

    setMapHasBeenClicked(true)
    setInputIsChanging(true)
    setShouldClearInput(false)

    onMapClick(val, { lat, lng })
  }

  const { renderMap, error, ready: mapIsReady } = useMap({
    container: mapRef,
    onChange: onMapChange,
    city: searchMarket,
    country: COUNTRY,
    marker: mapPin,
    setDefault: !editingAddress?.geocode,
  })

  const {
    isBolivia,
    state: { markets },
  } = useLocation()

  useEffect(() => {
    setTimeout(() => setHideErrorMessage(false), 3000)
    // Check for multiple regions
    const regions = parseMarketData(markets, currentMarket.name)

    if (regions) {
      setDistrictSettings({
        hasMultiple: true,
        data: regions,
      })
    }

    if (editingAddress?.shipping_address) {
      setShippingAddress(editingAddress?.shipping_address)
      setQuery(editingAddress?.shipping_address)
    }

    const hideOptionsOnClick = (e: Event) => {
      const target = e.target as HTMLElement

      if (!target.classList.contains('address_option')) {
        setShowOptions(false)
      }
    }

    window.addEventListener('click', hideOptionsOnClick)

    return () => window.removeEventListener('click', hideOptionsOnClick)
  }, [])

  useEffect(() => {
    if (inputRef.current?.defaultValue !== editingAddress?.shipping_address && !mapHasBeenClicked)
      setShowOptions(suggestions.length !== 0)
  }, [suggestions])

  useEffect(() => {
    if (!loadingGeocode && isSelected) {
      return onSelect(shippingAddress, geocode)
    }
  }, [isSelected, loadingGeocode])

  useEffect(() => {
    const coordinates = geocode !== undefined ? geocode : editingAddress?.geocode
    if (coordinates?.latitude && coordinates.longitude && mapIsReady) {
      renderMap({ lat: coordinates.latitude, lng: coordinates.longitude, markerIcon: mapPin })
    }
  }, [geocode, editingAddress?.geocode, mapIsReady])

  // Validate if address has a number
  const validate = (val: string) => {
    const isValid = /\d/.test(val)

    return isValid
  }

  const clearInput = () => {
    setShippingAddress('')

    // Focus input
    return inputRef.current?.focus()
  }

  useEffect(() => {
    if (shouldClearInput) {
      clearInput()
    }
  }, [shouldClearInput])

  useEffect(() => {
    // Typing timeout
    setInputIsChanging(true)
    clearTimeout(typingTimeout)

    setTipingTimeout(
      setTimeout(() => {
        setInputIsChanging(false)
      }, 1000),
    )
  }, [shippingAddress])

  const handleChange = (val: string, updateSelected = false, prediction?: Prediction): void => {
    setQuery(val, !updateSelected)
    setShippingAddress(val)
    setIsSelected(false)
    onInputChange(val)
    setMapHasBeenClicked(false)

    // Run if an option is selected
    if (updateSelected && validate(val)) {
      setSelected(prediction)
      setIsSelected(true)
      setShowOptions(false)
    }
  }

  useEffect(() => {
    const shouldShowError =
      suggestions.length === 0 &&
      !loadingSuggestions &&
      shippingAddress !== '' &&
      !inputIsChanging &&
      !mapHasBeenClicked
    setShowError(shouldShowError)
  }, [suggestions, loadingSuggestions, inputIsChanging, shippingAddress])

  // Update selected region
  useEffect(() => {
    const defaultZone = districtState.state.selectedZone?.name
    const newRegions = parseMarketData(markets, currentMarket.name)

    setSearchMarket(defaultZone ?? currentMarket.name)

    setDistrictSettings({
      hasMultiple: newRegions !== undefined,
      data: newRegions ?? null,
    })
  }, [currentMarket.name, districtState])

  const onZoneChange = (selected: string) => {
    const zone = districtSettings.data?.locations.filter((z) => z.id === parseInt(selected, 10))[0]
    setSearchMarket(`${zone?.name}`)
    clearInput()
    onInputChange('')

    return districtState.dispatcher(() => ({
      checkZone: districtState.state.checkZone,
      hasError: false,
      selectedZone: zone,
    }))
  }

  const handleChangeMarket = () => {
    handleAddressBack()
    setIsSelectingAddress(false)
    setIsChangingMarket(true)
  }

  const filterLocations = parseMarketData(markets, currentMarket.name)?.locations ?? []

  const filteredByDistributionCenter = filterLocations.filter(
    (location) => location.distributionCenterId === currentDistributionCenter?.id,
  )

  const mappedLocations = [
    ...filteredByDistributionCenter.map((location) => ({
      label: location.name,
      value: location.id,
    })),
  ].filter((location) => !secrets.EXCLUDE_ZONES.includes(location.label))

  return (
    <Wrapper>
      {(isDesktop || !isAuth) && (
        <Title>
          <Icon iconId="location" size="20" />
          Agregar nueva dirección
        </Title>
      )}
      <Location>
        Estás en <span>{currentMarket.name}</span>
        <Button className={toPrefix('edit-button')} btnType="tertiary" onClick={handleChangeMarket}>
          Cambiar
        </Button>
      </Location>
      <Row>
        <TextField
          data-testid="shipping-name"
          className={toPrefix('input')}
          label="Etiqueta"
          placeholder="Ingresa etiqueta (Ej: Mi Casa)"
          maxLength={isBolivia() ? 50 : undefined}
          type="text"
          ref={register}
          name="shipping_name"
          defaultValue={editingAddress?.shipping_name}
          status={!errors?.shipping_name ? undefined : 'error'}
          errorMessage={errors?.shipping_name?.message}
        />
      </Row>
      {!isBolivia() && (
        <Row>
          <TextField
            className={toPrefix('input')}
            label="Región"
            placeholder={currentMarket.name}
            status={!errors.market ? undefined : 'error'}
            errorMessage={errors.market?.message}
            type="text"
            disabled
          />
          <Select
            dataTest="checkout-district-select"
            className={toPrefix('input')}
            name="regions"
            options={mappedLocations}
            onSelect={onZoneChange}
            placeholder={districtState.state.selectedZone?.name ?? `Selecciona comuna`}
            label="Comuna"
            status={districtState.state.hasError ? 'error' : undefined}
            errorMessage={districtState.state.hasError ? `Debes seleccionar una comuna` : ''}
          />
        </Row>
      )}
      <Row>
        <TextField
          className={toPrefix('input-full')}
          placeholder="Ingresa dirección completa"
          label="Dirección completa (Calle y Número)"
          data-testid="shipping-address"
          ref={inputRef}
          autoComplete="off"
          disabled={!ready}
          onChange={({ target }) => handleChange(target.value)}
          value={shippingAddress}
          type="text"
          name="shipping_address"
          status={!addressIsValid ? 'error' : undefined}
          errorMessage={!addressIsValid ? 'Selecciona una dirección válida' : ''}
        />
        <div>
          <ResultsWrapper isShown={showOptions}>
            {suggestions.map((prediction) => (
              <button
                className="address_option"
                type="button"
                key={prediction.place_id}
                onClick={() => handleChange(prediction.description, true, prediction)}
              >
                {prediction.description}
              </button>
            ))}
            <Image
              preload={false}
              alt="powered-by-google"
              src="https://images.ctfassets.net/16npdkkoi5mj/3MhwfZQrA9vCdM25aOVTei/bb865ffdf05b31526b9c8e159ea8e9e7/Powered_by_Google.png?h=250"
              className="google-disclaimer"
            />
          </ResultsWrapper>
        </div>
      </Row>
      <Row>
        <TextField
          className={toPrefix('input')}
          label="Departamento / Casa / Oficina / Condominio (opcional)"
          placeholder="Ingresa número de depto / casa / oficina"
          data-testid="shipping-number"
          maxLength={isBolivia() ? 50 : undefined}
          ref={register}
          type="text"
          name="shipping_number"
          defaultValue={editingAddress?.shipping_number}
        />
        <TextField
          className={toPrefix('input')}
          label="Teléfono de contacto"
          placeholder="Ingresa teléfono de contacto"
          data-testid="shipping-phone"
          maxLength={isBolivia() ? 20 : undefined}
          type="tel"
          ref={register}
          name="shipping_phone"
          defaultValue={editingAddress?.shipping_phone}
          status={!errors?.shipping_phone ? undefined : 'error'}
          errorMessage={errors?.shipping_phone?.message}
        />
      </Row>
      <Row>
        <TextField
          className={toPrefix('input-full')}
          label="Indicaciones (Opcional)"
          placeholder="Ingrese indicaciones especiales para la entrega (Ej: Dejar en conserjería)"
          data-testid="shipping-instructions"
          name="shipping_instructions"
          defaultValue={editingAddress?.shipping_instructions}
          ref={register}
          maxLength={200}
          status={!errors.shipping_instructions ? undefined : 'error'}
          errorMessage={errors.shipping_instructions?.message}
        />
      </Row>
      <div ref={resultsContainer} />
      <MapWrapper id="map-wrapper">
        {!hideErrorMessage && (
          <InfoMessage
            isHidden={!showError}
            className="map-error"
            message="No hemos encontrado tu dirección, haz click en el mapa para selecionar tu ubicación"
          />
        )}
        <InfoMessage
          isHidden={!error.exists}
          className="map-error"
          message="Ha habido un error, por favor intenta de nuevo"
        />
        <div className="map-container" ref={mapRef} />
      </MapWrapper>
      {(isDesktop || !isAuth) && (
        <ContentButtons>
          {isAuth && (
            <Button className={toPrefix('cancel-button')} btnType="tertiary" onClick={handleAddressBack}>
              Volver
            </Button>
          )}
          <Button className={toPrefix('save-button')} btnType="primary" onClick={handleAddressSubmit}>
            Guardar
          </Button>
        </ContentButtons>
      )}
    </Wrapper>
  )
}

export default AddressForm
