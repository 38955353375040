import styled from 'styled-components'
import { breakpoints, hexToRGBA } from '@ecommerce/shared'

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 7px 0;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 11px 2px rgba(3, 15, 28, 0.15);
  @media (${breakpoints.desktop.min}) {
    padding: 15px 100px;
    justify-content: space-between;
  }
`

export const ButtonsContent = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 325px;
  @media (${breakpoints.desktop.min}) {
    max-width: 360px;
  }
`
export const BackButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  display: none;
  svg {
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
    margin-right: 6px;
  }
  @media (${breakpoints.desktop.min}) {
    display: flex;
  }
`
export const StepButton = styled.button<{ active?: boolean }>`
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
  color: ${({ theme, active }) => (active ? theme.colors.red : hexToRGBA(theme.colors.black80, 0.75))};
  transition: all 0.3s;
  height: fit-content;
  padding: 0;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    margin-right: 4px;
    padding: 6px;
    background-color: ${({ theme, active }) => (active ? theme.colors.red : 'transparent')};
  }
  svg {
    fill: ${({ theme, active }) => (active ? theme.colors.white : hexToRGBA(theme.colors.black80, 0.75))};
    transition: all 0.3s;
  }
  p {
    margin: 4px 0 0;
    font-size: 13px;
    line-height: 18px;
  }
  @media (${breakpoints.desktop.min}) {
    flex-direction: row;
  }
`
