import React from 'react'
import { Button, getStoredDistributionCenter, useResolution, ProductCart, toCurrency } from '@ecommerce/shared'
import { toPrefix, Wrapper, Row, Title, Separator, Detail, Discount, Total } from './Summary.styled'
import { SwiperCart } from '../SwiperCart'
import { CheckoutCart } from '../../CheckoutCart'
import { useCheckout } from '../../../checkout.context'

type Props = {
  setIsEdit: (showDetail: boolean) => void
  isEdit?: boolean
  isLoading: boolean
  isLoadingData: boolean
  onRemoveProduct: ({ skuCode, lineItemId }: ProductCart) => Promise<void>
  onUpdateCartState: () => void
  setIsLoading: (state: boolean) => void
}

const Summary = ({
  setIsEdit,
  isEdit = false,
  isLoading,
  isLoadingData,
  onRemoveProduct,
  onUpdateCartState,
  setIsLoading,
}: Props) => {
  const {
    cartState,
    globalQuantity: productsQuantity,
    reductionDiscount,
    orderTotalWithCurrency: displayTotal,
    promotionDiscounts,
    shippingCost,
    freeOver,
    freeOverWithCurrency,
    subtotal,
    getShippingCost,
    isAdditionalDiscounts,
    isExecutingPayment,
  } = useCheckout()

  const { isDesktop } = useResolution()

  const currentDistributionCenter = getStoredDistributionCenter()
  const handleEdit = (value: boolean) => {
    if (!isExecutingPayment) setIsEdit(value)
  }

  if (!currentDistributionCenter) return null

  return (
    <Wrapper>
      {productsQuantity && (
        <>
          <Row>
            <Title>
              Mi Carro<span>({productsQuantity} productos)</span>
            </Title>
            {!isEdit && (
              <Button
                className={toPrefix('edit-button')}
                onClick={() => handleEdit(true)}
                btnType="tertiary"
                disabled={!!isExecutingPayment}
              >
                Editar
              </Button>
            )}
          </Row>
          <Separator />
          {isEdit && isDesktop ? (
            <CheckoutCart
              handleEditProducts={handleEdit}
              onRemoveProduct={onRemoveProduct}
              setCartLoading={setIsLoading}
              currentDistributionCenter={currentDistributionCenter}
              onUpdateCartState={onUpdateCartState}
              isExecutingPayment={isExecutingPayment}
              updatingOrder={isLoading || !productsQuantity || productsQuantity <= 0 || isLoadingData}
            />
          ) : (
            <SwiperCart cartState={cartState} currentDistributionCenter={currentDistributionCenter} />
          )}
        </>
      )}
      <Detail>
        <span>Costo productos</span>
        <span>{subtotal}</span>
      </Detail>
      {reductionDiscount ? (
        <Discount>
          <span>
            Descuentos&nbsp;
            {isAdditionalDiscounts && 'adicionales'}
          </span>
          <span>{`- ${reductionDiscount}`}</span>
        </Discount>
      ) : null}
      {promotionDiscounts &&
        promotionDiscounts.map(({ name, amount, isGift }) => (
          <Discount>
            <span>{`${name} ${isGift ? '(regalo)' : ''}`}</span>
            <span>{`- ${amount}`}</span>
          </Discount>
        ))}
      <Detail>
        <span>Despacho</span>
        <span>{shippingCost}</span>
      </Detail>
      {freeOver && Number(freeOver) > 0 ? (
        <Discount>
          <span>Gratis desde {freeOverWithCurrency}</span>
          <span>-{getShippingCost.text}</span>
        </Discount>
      ) : null}
      <Separator className={toPrefix('margin-16')} />
      <Total>
        <span>Total a pagar</span>
        <span>{displayTotal}</span>
      </Total>
    </Wrapper>
  )
}

export default Summary
