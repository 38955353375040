import styled from 'styled-components'
import { toCssPrefix, breakpoints } from '@ecommerce/shared'

export const { toPrefix, cssPrefix } = toCssPrefix('MyAddresses__')

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
  .${cssPrefix} {
    &address-button {
      font-weight: 350;
      color: ${({ theme }) => theme.colors.red};
      font-size: 11px;
      font-weight: 350;
      padding: 0;
      background-color: transparent;
      height: auto;
    }
    &address-name {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 4px;
    }
    &add-address-button {
      display: flex;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.red};
      align-items: center;
      margin: 0 auto;
      font-size: 13px;
      height: auto;
      padding-top: 8px;
      svg {
        margin-right: 8px;
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &address-button {
        font-size: 13px;
      }
      &add-address-button {
        margin: 0;
        padding-top: 8px;
      }
      &save-button {
        font-size: 13px;
        padding: 6px 35px;
        height: auto;
      }
      &cancel-button {
        font-size: 13px;
        padding: 6px 35px;
        height: auto;
        margin-right: 16px;
        background-color: transparent;
        color: ${({ theme }) => theme.colors.red};
      }
    }
  }
`

export const Label = styled.div`
  font-weight: 600;
  font-size: 17px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  line-height: 25px;
  svg {
    margin-right: 4px;
  }
`

export const RadioInput = styled.input.attrs({ type: 'radio' })`
  background: transparent;
  cursor: pointer;
  appearance: none;
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
  border-radius: 50%;
  position: relative;
  border: 2px solid ${({ theme }) => theme.colors.red};
  margin-right: 8px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    background: ${({ theme }) => theme.colors.red};
    left: calc(50% - 6px);
    top: calc(50% - 6px);
    border-radius: 50%;
    opacity: 0;
    transition: 0.05s;
  }

  &:checked {
    &::after {
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (${breakpoints.desktop.min}) {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const ContentAddresses = styled.div`
  max-height: 443px;
  overflow-y: auto;
  margin-bottom: 8px;
`
export const AddressContent = styled.div`
  display: flex;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  padding: 16px;
  margin-bottom: 16px;
`

export const Data = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
  font-weight: 350;
  margin-bottom: 16px;
  line-height: 18px;
  width: 100%;
`

export const ContentButtons = styled.div`
  display: flex;
  justify-content: space-between;
  @media (${breakpoints.desktop.min}) {
    flex-direction: column;
  }
`
export const Actions = styled.div`
  display: flex;
`
