import React from 'react'
import { PaymentMethodType } from '@ecommerce/shared'
import { TabsOptionsWrapper, toPrefix } from './SelectPayment.styled'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  options: PaymentMethodType[]
  onOptionSelect: (selected: PaymentMethodType) => void
  activeOption: string
  isDisabled?: boolean
}

export const TabsOptions = ({ options, onOptionSelect, activeOption, isDisabled }: Props) => {
  return (
    <TabsOptionsWrapper>
      {options.map((option) => (
        <button
          key={option.value}
          onClick={() => onOptionSelect(option)}
          type="button"
          className={`${toPrefix('option')} ${activeOption === option.value ? 'is-active' : ''}`}
          title={option.label}
          disabled={isDisabled}
        >
          {option.label}
        </button>
      ))}
    </TabsOptionsWrapper>
  )
}
