import React, { useState, useRef, useEffect } from 'react'
import {
  useResolution,
  ProductCart,
  DistributionCenter,
  Order,
  OrderData,
  Market,
  Zone,
  Geocode,
  PaymentTypes,
} from '@ecommerce/shared'
import CouponCode from '../CouponCode'
import Summary from './Summary'
import { Wrapper } from './OrderDetail.styled'
import Confirm from './Confirm'
import { useCheckout } from '../../checkout.context'
import BottomSheet from '../../../BottomSheet'
import { ContentEdit, toPrefix } from '../../Checkout.styled'
import { CheckoutCart } from '../CheckoutCart'
import Card from '../../../Card'
import Detail from './Detail'

type Props = {
  disableButton: boolean
  checkStockCallback: (hasValidStock: boolean, products?: ProductCart[]) => void
  currentDistributionCenter: DistributionCenter
  onRemoveProduct: ({ skuCode, lineItemId }: ProductCart) => Promise<void>
  onUpdateCartState: () => void
  orderId: string
  handleChange: (name: keyof Order, value: boolean) => void
  orderData: OrderData
  currentMarket: Market
  currentZone: Zone
  geocode?: Geocode
  selectedPaymentType: PaymentTypes
  refSessionId: string
  refGuestUser: {
    firstName?: string
    lastName?: string
  }
}
const OrderDetail = ({
  disableButton,
  currentDistributionCenter,
  onRemoveProduct,
  onUpdateCartState,
  orderId,
  checkStockCallback,
  handleChange,
  orderData,
  currentMarket,
  currentZone,
  geocode,
  selectedPaymentType,
  refSessionId,
  refGuestUser,
}: Props) => {
  const { isDesktop } = useResolution()
  const orderDetailRef = useRef<HTMLInputElement | null>(null)

  const {
    currentStep,
    isLoadingCoupon,
    isLoadingCartState,
    isExecutingPayment,
    globalQuantity: productsQuantity,
    isAddingAddress,
    editingAddress,
  } = useCheckout()

  const [isLoading, setIsLoading] = useState(false)
  const [showDetail, setShowDetail] = useState(true)
  const [editProducts, setEditProducts] = useState(false)

  const isLoadingData = isLoadingCoupon || isLoadingCartState

  const handleEditProducts = (state: boolean) => {
    setEditProducts(state)
  }

  const handleScroll = () => {
    const sentinel = orderDetailRef.current
    if (sentinel) {
      const componentPosition = sentinel.getBoundingClientRect()
      const isVisible = componentPosition.top >= window.innerHeight
      setShowDetail(isVisible)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    handleScroll()
  }, [currentStep])

  const mobileContent = (
    <ContentEdit>
      <CheckoutCart
        handleEditProducts={handleEditProducts}
        onRemoveProduct={onRemoveProduct}
        setCartLoading={setIsLoading}
        currentDistributionCenter={currentDistributionCenter}
        onUpdateCartState={onUpdateCartState}
        isExecutingPayment={isExecutingPayment}
        updatingOrder={isLoading || !productsQuantity || productsQuantity <= 0 || isLoadingData}
      />
      {currentStep !== 1 && (
        <Card>
          <CouponCode orderId={orderId ?? ''} />
        </Card>
      )}
      <Card>
        <Wrapper>
          <Summary
            setIsEdit={setEditProducts}
            isEdit={editProducts}
            isLoading={isLoading}
            isLoadingData={isLoadingData}
            onRemoveProduct={onRemoveProduct}
            setIsLoading={setIsLoading}
            onUpdateCartState={onUpdateCartState}
          />
          {currentStep !== 1 && !editProducts && (
            <Confirm
              disableButton={disableButton}
              handleChange={handleChange}
              checkStockCallback={checkStockCallback}
              orderData={orderData}
              currentMarket={currentMarket}
              currentZone={currentZone}
              geocode={geocode}
              orderId={orderId}
              selectedPaymentType={selectedPaymentType}
              refSessionId={refSessionId}
              refGuestUser={refGuestUser}
            />
          )}
        </Wrapper>
      </Card>
    </ContentEdit>
  )

  return (
    <>
      {currentStep !== 1 && !isDesktop && (
        <Card>
          <CouponCode orderId={orderId ?? ''} />
        </Card>
      )}
      {showDetail && !isDesktop && !isAddingAddress && !editingAddress && (
        <Detail
          setIsEdit={setEditProducts}
          disableButton={disableButton}
          checkStockCallback={checkStockCallback}
          orderData={orderData}
          currentMarket={currentMarket}
          currentZone={currentZone}
          geocode={geocode}
          orderId={orderId}
          selectedPaymentType={selectedPaymentType}
          refSessionId={refSessionId}
          refGuestUser={refGuestUser}
        />
      )}
      <Card className={toPrefix('order-detail')}>
        <Wrapper ref={orderDetailRef}>
          <Summary
            setIsEdit={setEditProducts}
            isEdit={editProducts}
            isLoading={isLoading}
            isLoadingData={isLoadingData}
            onRemoveProduct={onRemoveProduct}
            setIsLoading={setIsLoading}
            onUpdateCartState={onUpdateCartState}
          />
          {currentStep !== 1 && isDesktop && <CouponCode orderId={orderId ?? ''} className="margin-40" />}
          {currentStep !== 1 && (
            <Confirm
              disableButton={disableButton}
              handleChange={handleChange}
              checkStockCallback={checkStockCallback}
              orderData={orderData}
              currentMarket={currentMarket}
              currentZone={currentZone}
              geocode={geocode}
              orderId={orderId}
              selectedPaymentType={selectedPaymentType}
              refSessionId={refSessionId}
              refGuestUser={refGuestUser}
            />
          )}
        </Wrapper>
      </Card>
      <BottomSheet
        title="Mi Carro"
        buttonLabel="Continuar"
        onSubmit={() => setEditProducts(false)}
        isOpen={editProducts}
        onClose={() => setEditProducts(false)}
        icon="cart_0"
      >
        {mobileContent}
      </BottomSheet>
    </>
  )
}

export default OrderDetail
