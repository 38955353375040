import React from 'react'
import styled from 'styled-components'
import { Button, breakpoints, ModalBox } from '@ecommerce/shared'

type Props = {
  onClose: () => void
  city?: string
  mapID?: string
}

const Wrapper = styled.div`
  text-align: center;
  padding: 30px 45px;

  p {
    font-size: 18px;

    &.notice {
      color: ${({ theme }) => theme.colors.black};
    }

    &.location {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.red};
    }
  }

  button {
    margin: 45px auto auto;
    width: 215px;
    min-width: 215px;
    max-width: 215px;
    white-space: nowrap;
    display: block;
  }

  @media screen and (${breakpoints.tabletLandscape.max}) {
    padding: 30px;
  }
`

const MapBox = styled.div`
  height: 270px;

  @media screen and (${breakpoints.tabletLandscape.max}) {
    height: 400px;
  }
`

const Map = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`

const OutOfRangeModal = ({ onClose, city, mapID }: Props) => (
  <ModalBox onClose={onClose} title="Sin cobertura">
    <Wrapper>
      <p className="notice">Lamentablemente tu dirección está afuera de nuestra zona de cobertura.</p>
      <p className="location">{city}</p>
      <MapBox>
        <Map src={`${process.env.GATSBY_GOOGLE_MAPS_URL_EMBED}${mapID}`} />
      </MapBox>
      <Button onClick={onClose}>Prueba otra dirección</Button>
    </Wrapper>
  </ModalBox>
)

export default OutOfRangeModal
