import React from 'react'
import { TextField, useLocation } from '@ecommerce/shared'
import { toPrefix, InfoMessage, Label, Wrapper, ApplyButton, Loader } from './CouponCode.styled'
import { Icon } from '../../../Icon/Icon'
import { useCheckout } from '../../checkout.context'
import { useCoupon } from './useCoupon'

type Props = {
  canUndo?: boolean
  className?: string
  orderId: string
}
const CouponCode = ({ canUndo = true, className, orderId }: Props) => {
  const { isBolivia } = useLocation()

  const { isExecutingPayment, getCartStateFromCommerceLayer, setCartState } = useCheckout()

  const { errorState, setCouponCode, isSuccess, isLoading, onApplyCoupon, onRemoveCoupon, couponCode } = useCoupon({
    orderId,
  })

  const isDisabled = isExecutingPayment

  const applyCoupon = async () => {
    await onApplyCoupon()
    getCartStateFromCommerceLayer(setCartState)
  }

  const removeCoupon = async () => {
    onRemoveCoupon()
    getCartStateFromCommerceLayer(setCartState)
  }

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget

    setCouponCode(value)
  }

  return (
    <Wrapper className={className}>
      <Label> {isBolivia() ? 'Giftcard / Cupón de descuento' : 'Cupón de descuento'}</Label>
      <TextField
        data-testid="coupon-code"
        min={6}
        disabled={isLoading || isSuccess}
        onChange={handleChange}
        value={couponCode}
        className={toPrefix('text-field')}
        placeholder="Ingresa tu código de descuento"
        name="coupon-code"
        status={errorState.hasError ? 'error' : undefined}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            e.stopPropagation()
          }
        }}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {(isSuccess && canUndo) || (couponCode.length > 5 && !isSuccess) ? (
            <ApplyButton
              onClick={() => {
                if (isDisabled) return null
                return isSuccess && canUndo ? removeCoupon() : applyCoupon()
              }}
              type="button"
              disabled={isDisabled}
            >
              {isSuccess && canUndo ? 'Quitar' : 'Aplicar'}
            </ApplyButton>
          ) : null}
        </>
      )}
      {isSuccess || errorState.hasError ? (
        <InfoMessage type={isSuccess ? 'success' : 'error'}>
          <Icon sizeHeight={15} sizeWidth={15} iconId={isSuccess ? 'success' : 'error_outline'} />
          <p className="success-message">
            {errorState.hasError ? errorState.message : 'El cupón fue aplicado con éxito'}
          </p>
        </InfoMessage>
      ) : null}
    </Wrapper>
  )
}

export default CouponCode
