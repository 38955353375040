import React, { useEffect, useState } from 'react'
import { useLocation, PaymentMethodType, PaymentTypes, BillingData } from '@ecommerce/shared'
import { Wrapper } from './PaymentMethods.styled'
import Card from '../../../Card'
import AddressInfo from './AddressInfo'
import GiftCard from './GiftCard'
import SelectPayment from './SelectPayment'
import { TemplateCheckout } from '../../../../types/PgPages'
import { useCheckout } from '../../checkout.context'
import usePaymentMethods from './usePaymentMethods'
import { getPaymentTypeChile } from '../../utils'
import { ErrorMessage } from '../../../../utils/errors'
import SecurePayment from './SecurePayment'

type Props = {
  templateData: TemplateCheckout
  onChange: (value: string, name?: string) => void
  onPaymentTypeChange: (type: PaymentTypes) => void
  onBillingSubmit: (data: BillingData) => void
  onBillingChange: (isShown: boolean) => void
  orderId: string
}
const PaymentMethods = ({
  templateData,
  onChange,
  orderId,
  onPaymentTypeChange,
  onBillingSubmit,
  onBillingChange,
}: Props) => {
  const { isBolivia } = useLocation()
  const { selectedAddress, setIsQRSelected, processErrors, setCurrentStep, setSelectPaymentError } = useCheckout()
  const [paymentSelected, setPaymentSelected] = useState<string | null>()

  const {
    privacyPolicyLink,
    paymentSourceLogo: {
      file: { url: paymentSourceLogo },
    },
  } = templateData

  const {
    fetchPayments,
    paymentMethods,
    paymentType,
    setPaymentType,
    staticPaymentMethods,
    paymentItems,
  } = usePaymentMethods({ paymentSourceLogo })

  useEffect(() => {
    onChange('')
    setPaymentSelected(null)
    fetchPayments()
  }, [])

  const isBO = isBolivia()

  const handlePaymentTypeChange = (selected: PaymentMethodType): void => {
    const type = selected.value

    setPaymentType(type)
    onChange('')
    setPaymentSelected(null)
    return onPaymentTypeChange(type)
  }

  const onOptionChange = (value: string, label?: string) => {
    const hasQR = staticPaymentMethods.find((option) => `${option.QRID}` === value)

    if (paymentType === PaymentTypes.CONTRA_ENTREGA || paymentType === PaymentTypes.QR) {
      // BO payments
      setPaymentType(hasQR ? PaymentTypes.QR : PaymentTypes.CONTRA_ENTREGA)
    } else {
      // CL Payments
      const actualPaymentType = isBO ? PaymentTypes.REDENLACE : getPaymentTypeChile(label)
      setPaymentType(actualPaymentType)
      onPaymentTypeChange(actualPaymentType)
    }

    setIsQRSelected(!!hasQR)
    if (hasQR) onPaymentTypeChange(PaymentTypes.QR)

    setSelectPaymentError(false)
    setPaymentSelected(label)
    return onChange(value, label)
  }

  return (
    <Wrapper>
      <Card>
        <AddressInfo setCurrentStep={setCurrentStep} selectedAddress={selectedAddress} />
      </Card>
      <Card>
        {processErrors.paymentMethods && (
          <ErrorMessage title="Error" content="Ha habido un error obteniendo los medios de pago disponibles" />
        )}
        <SelectPayment
          paymentMethods={paymentMethods}
          onOptionChange={onOptionChange}
          handlePaymentTypeChange={handlePaymentTypeChange}
          paymentType={paymentType}
          paymentItems={paymentItems}
          paymentSelected={paymentSelected}
        />
      </Card>
      {!isBolivia() && (
        <Card>
          <GiftCard orderId={orderId} />
        </Card>
      )}
      <Card>
        <SecurePayment
          privacyPolicyLink={privacyPolicyLink}
          onBillingSubmit={onBillingSubmit}
          onBillingChange={onBillingChange}
        />
      </Card>
    </Wrapper>
  )
}

export default PaymentMethods
