import React from 'react'
import { PaymentMethodsObject, PaymentTypes, useLocation, RadioInput, useShoppingCart } from '@ecommerce/shared'
import { Wrapper, Title, Description, toPrefix } from './SelectPayment.styled'
import { Icon } from '../../../../Icon/Icon'
import { PaymentOption } from './PaymentOption'
import { TabsOptions } from './TabsOptions'
import AlertMessage from '../../../../AlertMessage'
import { useCheckout } from '../../../checkout.context'

type Props = {
  paymentMethods: PaymentMethodsObject
  onOptionChange: (value: string, label?: string) => void
  handlePaymentTypeChange: (selected: any) => void
  paymentType: PaymentTypes
  paymentItems: RadioInput[]
  paymentSelected?: string | null
}
const SelectPayment = ({
  paymentType,
  paymentMethods,
  onOptionChange,
  handlePaymentTypeChange,
  paymentItems,
  paymentSelected,
}: Props) => {
  const { textByCountry } = useLocation()

  const { isExecutingPayment, giftCardCreditBalance, orderTotal } = useCheckout()

  const isDisabled = isExecutingPayment || giftCardCreditBalance() > 0 || giftCardCreditBalance() === orderTotal

  return (
    <Wrapper isDisabled={isDisabled}>
      <Title>
        <Icon iconId="creditcard" size="20" />
        {textByCountry('Medio de pago', 'Forma de pago')}
      </Title>
      <Description>Selecciona tu medio de pago</Description>
      {paymentMethods?.types.length !== 0 && (
        <TabsOptions
          activeOption={paymentType === PaymentTypes.QR ? PaymentTypes.CONTRA_ENTREGA : paymentType}
          onOptionSelect={handlePaymentTypeChange}
          options={paymentMethods.types}
          isDisabled={isDisabled}
        />
      )}
      {paymentType === PaymentTypes.CONTRA_ENTREGA && (
        <AlertMessage className={toPrefix('alert')} type="info">
          Deberás realizar el pago al repartidor al momento de recibir el pedido.
        </AlertMessage>
      )}
      <PaymentOption
        className="RadioButtons PaymentOptions"
        name="payment"
        items={paymentItems}
        onChange={(e, label) => {
          onOptionChange(e.target.value, label)
        }}
        paymentSelected={paymentSelected}
        dataTest="checkout-payment-option"
        isDisabled={isDisabled}
      />
    </Wrapper>
  )
}

export default SelectPayment
