import { ProductCart as ProductCartType, DistributionCenter, useResolution, ProductPromotion } from '@ecommerce/shared'
import Button from '@ecommerce/shared/src/components/atoms/Button'
import React, { useEffect, useState } from 'react'
import secrets from '../../../../config/secrets'
import { WrapperDetailCart } from '../../../ShoppingCart/ShoppingCart.styled'
import GiftProducts from '../../../ShoppingCart/GiftProducts'
import ProductCart from '../../../ShoppingCart/ProductCart'
import { WrapperCheckoutCart, toPrefix } from './CheckoutCart.styled'
import { useCheckout } from '../../checkout.context'

const getProductPromotion = (promotion: ProductPromotion | undefined, activationQuantity: number | undefined) => {
  if (promotion && activationQuantity) {
    return {
      ...promotion,
      activationQuantity,
    }
  }
  return undefined
}

export const CheckoutCart = ({
  handleEditProducts,
  currentDistributionCenter,
  isExecutingPayment = false,
  updatingOrder = false,
  onRemoveProduct,
  setCartLoading,
  onUpdateCartState,
}: {
  handleEditProducts: (state: boolean) => void
  onRemoveProduct: ({ skuCode, lineItemId }: ProductCartType) => Promise<void>
  setCartLoading: (state: boolean) => void
  currentDistributionCenter: DistributionCenter
  onUpdateCartState: () => void
  isExecutingPayment?: boolean
  updatingOrder?: boolean
}) => {
  const { isDesktop } = useResolution()

  const { cartState, orderTotal } = useCheckout()
  const {
    cartState: { globalRawTotal: subTotal },
  } = useCheckout()

  const skus = Object.keys(cartState.byHash)
  const minSubtotal = Number(secrets.MIN_RAW_TOTAL)
  const isSubtotalValid = cartState.globalTotal >= minSubtotal

  const [giftLineItems, setGiftLineItems] = useState<Array<{ quantity: number; sku: number }>>()
  const [loadingPrice, setLoadingPrice] = useState(false)

  useEffect(() => {
    setGiftLineItems(
      cartState?.giftLineItems?.map(({ sku, discountQuantity = 0 }) => ({
        sku: Number(sku),
        quantity: discountQuantity,
      })) || [],
    )
  }, [cartState.giftLineItems])

  return (
    <>
      <WrapperCheckoutCart isExecutingPayment={!!isExecutingPayment}>
        <WrapperDetailCart isCheckout>
          {skus.map((skuCode) => (
            <ProductCart
              presale={Number(skuCode) === secrets.JW_PRESALE_SKU}
              key={skuCode}
              product={cartState.byHash[skuCode]}
              promotion={
                cartState.promotions && {
                  ...cartState.promotions[skuCode],
                  promotion: getProductPromotion(
                    cartState.promotions[skuCode]?.promotion,
                    cartState.discountDetails?.find((item) => item.sku === skuCode)?.promotion?.activationQuantity,
                  ),
                }
              }
              setLoadingPrice={setLoadingPrice}
              loadingPrice={loadingPrice}
              onRemoveProduct={onRemoveProduct}
              lineItemId={cartState.byHash[skuCode].lineItemId}
              setCartLoading={setCartLoading}
              isCheckout
              orderTotal={typeof orderTotal === 'string' ? parseInt(orderTotal, 10) : orderTotal}
              subTotal={subTotal}
              giftsLineItemsRelated={cartState.giftLineItems?.filter(
                (gift) => gift.promotion?.skus?.includes(Number(skuCode)) ?? false,
              )}
              onUpdateCartState={onUpdateCartState}
              disableButtons={isExecutingPayment || updatingOrder}
              updatingOrder={updatingOrder}
            />
          ))}
          {isSubtotalValid ? (
            <GiftProducts
              key={giftLineItems?.length}
              slugLocation={currentDistributionCenter.slug}
              items={giftLineItems}
              updatingOrder={updatingOrder}
            />
          ) : null}
        </WrapperDetailCart>
        {isDesktop && (
          <Button
            btnType="secondary"
            onClick={() => handleEditProducts(false)}
            className={toPrefix('btn-see-less')}
            isDisabled={!!isExecutingPayment}
            disabled={!!isExecutingPayment}
          >
            ver menos
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.410582 7.08991C0.710985 7.39031 1.18368 7.41342 1.51059 7.15923L1.58909 7.08991L5.99984 2.67982L10.4106 7.08991C10.711 7.39031 11.1837 7.41342 11.5106 7.15923L11.5891 7.08991C11.8895 6.7895 11.9126 6.31681 11.6584 5.9899L11.5891 5.9114L6.58909 0.911395C6.28869 0.610992 5.816 0.587883 5.48909 0.842071L5.41058 0.911395L0.410582 5.9114C0.0851447 6.23683 0.0851447 6.76447 0.410582 7.08991Z"
                fill="#F40009"
              />
            </svg>
          </Button>
        )}
      </WrapperCheckoutCart>
    </>
  )
}
