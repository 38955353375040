import styled, { css } from 'styled-components'
import { toCssPrefix } from '@ecommerce/shared'

export const { cssPrefix, toPrefix } = toCssPrefix('CheckoutCart__')

export const WrapperCheckoutCart = styled.div<{ isExecutingPayment: boolean }>`
  .${cssPrefix} {
    &forgot-something {
      font-size: 14px;
      font-weight: 400;

      button {
        outline: none;
        border: none;
        background: none;
        font-size: inherit;
        font-weight: bold;
        color: ${({ theme }) => theme.colors.red};

        ${({ isExecutingPayment }) =>
          !isExecutingPayment &&
          css`
            cursor: pointer;
          `}
      }
    }

    &btn-see-less {
      display: block;
      margin: 20px auto 21px auto;
      padding: 9px 78px;

      svg {
        margin-left: 5px;
        margin-bottom: 1px;
      }

      &:hover,
      &:disabled {
        svg > path {
          fill: ${({ theme }) => theme.colors.white};
        }
      }

      &:disabled {
        cursor: default !important;
      }
    }
  }
`
