import React, { useState } from 'react'
import { FormattedAddress, Button, useResolution, getStoredDistributionCenter } from '@ecommerce/shared'
import {
  Wrapper,
  Label,
  RadioInput,
  AddressContent,
  ContentAddresses,
  Data,
  ContentButtons,
  Content,
  toPrefix,
  Actions,
} from './MyAddresses.styled'
import { Icon } from '../../../../Icon/Icon'
import Card from '../../../../Card'
import BottomSheet from '../../../../BottomSheet'
import ConfirmationAlert from '../../../../ConfirmationAlert'
import { AlertTitle } from '../MyDelivery.styled'

type Props = {
  addresses: FormattedAddress[]
  isSelectingAddress: boolean
  setIsSelectingAddress: (value: boolean) => void
  handleOpenAddressForm: (address?: FormattedAddress) => void
  handleAddressCheck: (isChecked: boolean, key: number) => void
  selectedAddress: FormattedAddress | null
  handleDeleteAddress: (id: string, parent?: string) => void
}

type AddressType = {
  key?: number
} & FormattedAddress

const MyAddresses = ({
  addresses,
  isSelectingAddress,
  setIsSelectingAddress,
  handleOpenAddressForm,
  handleAddressCheck,
  selectedAddress,
  handleDeleteAddress,
}: Props) => {
  const currentDistributionCenter = getStoredDistributionCenter()

  const { isDesktop } = useResolution()

  const [newSelectedAddress, setNewSelectedAddress] = useState<AddressType | null>(selectedAddress)

  const onConfirm = () => {
    if (newSelectedAddress?.id !== selectedAddress?.id) {
      handleAddressCheck(true, newSelectedAddress?.key || 0)
    }
    setIsSelectingAddress(false)
  }

  if (isDesktop)
    return (
      <Wrapper>
        <Card>
          <Label>
            <Icon iconId="location" size="20" />
            Mis Direcciones
          </Label>
          <ContentAddresses>
            {addresses &&
              addresses.map((address, index) => {
                if (address.distributionCenterId === currentDistributionCenter?.id) {
                  return (
                    <Address
                      key={address.id}
                      address={address}
                      handleOpenAddressForm={handleOpenAddressForm}
                      newSelectedAddress={newSelectedAddress}
                      setNewSelectedAddress={setNewSelectedAddress}
                      indexKey={index}
                      handleDeleteAddress={handleDeleteAddress}
                    />
                  )
                }
                return null
              })}
          </ContentAddresses>
          <Content>
            <Button
              className={toPrefix('add-address-button')}
              btnType="tertiary"
              onClick={() => handleOpenAddressForm()}
            >
              <Icon iconId="addcircle_outline" size="20" />
              Agregar nueva dirección
            </Button>
            <Actions>
              <Button
                className={toPrefix('cancel-button')}
                btnType="tertiary"
                onClick={() => setIsSelectingAddress(false)}
              >
                Cancelar
              </Button>
              <Button className={toPrefix('save-button')} btnType="primary" onClick={onConfirm}>
                Guardar
              </Button>
            </Actions>
          </Content>
        </Card>
      </Wrapper>
    )

  return (
    <BottomSheet
      title="Mis direcciones"
      buttonLabel="Guardar"
      onSubmit={() => {
        setIsSelectingAddress(false)
        onConfirm()
      }}
      isOpen={isSelectingAddress}
      onClose={() => setIsSelectingAddress(false)}
      icon="location"
    >
      <Wrapper>
        {addresses &&
          addresses.map((address, index) => (
            <Address
              key={address.id}
              address={address}
              handleOpenAddressForm={handleOpenAddressForm}
              newSelectedAddress={newSelectedAddress}
              setNewSelectedAddress={setNewSelectedAddress}
              indexKey={index}
              handleDeleteAddress={handleDeleteAddress}
            />
          ))}
        <Button className={toPrefix('add-address-button')} btnType="tertiary" onClick={() => handleOpenAddressForm()}>
          <Icon iconId="addcircle_outline" size="20" />
          Agregar nueva dirección
        </Button>
      </Wrapper>
    </BottomSheet>
  )
}

const Address = ({
  address,
  handleOpenAddressForm,
  newSelectedAddress,
  indexKey,
  setNewSelectedAddress,
  handleDeleteAddress,
}: {
  address: FormattedAddress
  handleOpenAddressForm: (address?: FormattedAddress) => void
  newSelectedAddress: AddressType | null
  setNewSelectedAddress: (address: AddressType | null) => void
  handleDeleteAddress: (id: string, parent?: string) => void
  indexKey: number
}) => {
  const [isDeleting, setIsDeleting] = useState(false)
  const Title = () => (
    <AlertTitle>
      <Icon iconId="trash" size="20" />
      Eliminar dirección
    </AlertTitle>
  )

  return (
    <AddressContent key={address.id}>
      {isDeleting && (
        <ConfirmationAlert
          onClose={() => setIsDeleting(false)}
          onBlur={() => setIsDeleting(false)}
          onConfirm={() => handleDeleteAddress(address.id, address.customer_address)}
          confirmButtonText="Eliminar"
          cancelButtonText="Volver"
          description="¿Estás seguro que quieres eliminar la dirección?"
          text={<Title />}
        />
      )}
      <RadioInput
        className="RadioButtons PaymentOptions"
        name="payment"
        checked={newSelectedAddress?.id === address.id}
        onChange={() => setNewSelectedAddress({ ...address, key: indexKey })}
      />
      <Content>
        <Data>
          <span className={toPrefix('address-name')}>{address.shipping_name}</span>
          <span>{address.name}</span>
          <span>{address.shipping_address}</span>
          <span>{address.marketName}</span>
          <span>{address.shipping_number}</span>
          <span>{address.shipping_phone}</span>
        </Data>
        <ContentButtons>
          <Button
            className={toPrefix('address-button')}
            btnType="tertiary"
            onClick={() => handleOpenAddressForm(address)}
          >
            Editar
          </Button>
          <Button className={toPrefix('address-button')} btnType="tertiary" onClick={() => setIsDeleting(true)}>
            Eliminar
          </Button>
        </ContentButtons>
      </Content>
    </AddressContent>
  )
}

export default MyAddresses
