import React from 'react'
import { Button, FormattedAddress, toWeekDay } from '@ecommerce/shared'
import { Wrapper, Row, Label, toPrefix, Content } from './AddressInfo.styled'
import { useCheckout } from '../../../checkout.context'

type Props = {
  setCurrentStep: (step: number) => void
  selectedAddress: FormattedAddress | null
}
const AddressInfo = ({ setCurrentStep, selectedAddress }: Props) => {
  const { deliveryDate } = useCheckout()

  return (
    <Wrapper>
      <Row>
        <Label>Resumen de entrega</Label>
        <Button
          className={toPrefix('edit-button')}
          btnType="tertiary"
          onClick={() => {
            setCurrentStep(2)
          }}
        >
          Editar
        </Button>
      </Row>
      <Content>
        <span>
          {selectedAddress?.shipping_address},{selectedAddress?.marketName}
        </span>
        <span>{toWeekDay(deliveryDate).label}</span>
      </Content>
    </Wrapper>
  )
}

export default AddressInfo
