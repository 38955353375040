import styled from 'styled-components'
import { breakpoints } from '@ecommerce/shared'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  column-gap: 24px;
  justify-content: center;
  @media (${breakpoints.desktop.min}) {
    flex-direction: row;
    grid-template-columns: 1fr 1fr;
  }
`
