import React from 'react'
import { navigate } from 'gatsby'
import { Wrapper, ButtonsContent, BackButton, StepButton } from './Navbar.styled'
import { Icon } from '../../../Icon/Icon'
import { stepProps } from '../..'

type Props = {
  steps: stepProps[]
  currentStep: number
}

const Navbar = ({ steps, currentStep }: Props) => {
  return (
    <Wrapper>
      <BackButton onClick={() => navigate(-1)}>
        <Icon iconId="arrow_left" />
        Volver paso anterior
      </BackButton>
      <ButtonsContent>
        {steps.map((step, index) => (
          <StepButton key={`navbar-${index}`} active={currentStep === step.id}>
            <div className="icon">
              <Icon iconId={step.icon} size={20} />
            </div>
            <p>{step.title}</p>
          </StepButton>
        ))}
      </ButtonsContent>
    </Wrapper>
  )
}

export default Navbar
