import styled, { css } from 'styled-components'
import { hexToRGBA, toCssPrefix } from '@ecommerce/shared'

export const { cssPrefix, toPrefix } = toCssPrefix('PaymentMethods__')

export const Wrapper = styled.div<{ isDisabled: boolean }>`
  color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
  .${cssPrefix} {
    &alert {
      margin-bottom: 24px;
    }
  }
  ${(props) => {
    if (props.isDisabled) {
      return css`
        color: ${({ theme }) => theme.semanticColors.text.body.secondary};
      `
    }
  }}
`

export const PaymentOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const Title = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.254px;
  margin-bottom: 8px;
  svg {
    margin-right: 8px;
  }
`

export const Description = styled.div`
  font-size: 13px;
  font-weight: 325;
  line-height: 18px;
  letter-spacing: -0.06px;
  margin-bottom: 24px;
`

export const InputWrapper = styled.div<{ isDisabled: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: start;
  margin-bottom: 8px;
  padding: 16px 10px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => hexToRGBA(theme.colors.grey80, 0.75)};

  span {
    font-size: 16px;
    font-weight: 325;
    line-height: 20px;
    letter-spacing: -0.2px;
  }

  img {
    max-width: 120px;
    max-height: 24px;
    object-fit: contain;
    ${(props) => {
      if (props.isDisabled) {
        return css`
          filter: grayscale(100%);
        `
      }
    }}
  }

  p {
    width: 100%;
    font-size: 15px;
    margin-bottom: 0;
    color: ${({ theme }) => theme.colors.black};
  }
`

export const CheckboxLabel = styled.div`
  display: flex;
  align-items: center;
`

export const RadioInput = styled.input.attrs({ type: 'radio' })`
  background: transparent;
  cursor: pointer;
  appearance: none;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  border-radius: 50%;
  position: relative;
  border: 2px solid ${({ theme }) => theme.colors.red};
  margin-right: 8px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    background: ${({ theme }) => theme.colors.red};
    left: calc(50% - 4px);
    top: calc(50% - 4px);
    border-radius: 50%;
    opacity: 0;
    transition: 0.05s;
  }

  &:checked {
    &::after {
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    border: 2px solid ${({ theme }) => theme.semanticColors.text.body.secondary};
  }
`

export const TabsOptionsWrapper = styled.div`
  width: fit-content;
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  margin-bottom: 24px;

  .${cssPrefix} {
    &option {
      padding: 0 24px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: fit-content;
      background: none;
      border: none;
      border-bottom: 2px solid ${({ theme }) => theme.colors.grey};
      color: ${({ theme }) => theme.colors.platinum80};
      position: relative;
      cursor: pointer;
      transition: color 0.2s;
      border-bottom-color: ${({ theme }) => theme.colors.grey};

      &.is-active {
        color: ${({ theme }) => theme.colors.red};
        border-bottom-color: ${({ theme }) => theme.colors.red};
        font-weight: bold;
      }

      &:first-child {
        &::after {
          content: '';
          display: block;
          position: absolute;
          right: 0;
          height: 45px;
          width: 1px;
          top: 10px;
          background: ${({ theme }) => theme.colors.grey};
        }
      }
    }
  }
`
