import styled, { css } from 'styled-components'
import { hexToRGBA, toCssPrefix } from '@ecommerce/shared'

export const { cssPrefix, toPrefix } = toCssPrefix('CheckoutSwiperCart__')

export const SwiperWrapper = styled.div<{ showButtonLeft: boolean }>`
  margin-top: 25px;
  margin-bottom: 26px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .swiper {
    margin-left: ${({ showButtonLeft }) => (showButtonLeft ? 'auto' : '0')};
    margin-right: ${({ showButtonLeft }) => (showButtonLeft ? '0' : 'auto')};

    &-button {
      &-next,
      &-prev {
        display: none;
      }
    }
  }

  .${cssPrefix} {
    &swiper-slide {
      min-width: 64px;
      max-width: 64px;
      width: 64px;
      margin-right: 12px;
    }

    &slide-content {
      position: relative;
      padding-top: 8px;

      img {
        width: 64px;
        height: 64px;
        border-radius: 5px;
      }

      &-amount {
        position: absolute;
        background: red;
        color: white;
        border-radius: 50%;
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        font-weight: 400;
      }

      &-gift {
        position: absolute;
        background: ${({ theme }) => theme.colors.green};
        color: white;
        border-radius: 50%;
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        font-weight: 400;
      }
    }
  }
`

export const SwiperButton = styled.button<{ showButtonLeft: boolean; showButton: boolean }>`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => hexToRGBA(theme.colors.black60, 0.5)};
  border-radius: 50%;
  height: 20px;
  width: 20px;
  transition: 0.3s;
  border: none;
  margin: 0 auto;
  margin: ${({ showButtonLeft }) => (showButtonLeft ? 'auto 5px auto 0' : 'auto 0 auto 5px')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ showButton }) =>
    !showButton &&
    css`
      display: none;
    `}

  &:hover {
    opacity: 0.75;
    background-color: ${({ theme }) => theme.colors.red};
  }
`
