import React, { ReactNode, useEffect } from 'react'
import { Button, Anchor } from '@ecommerce/shared'
import { useAnimation } from 'framer-motion'
import { Icon, IconId } from '../Icon/Icon'
import { Wrapper, BottomSheetContainer, Head, Title, Content, Footer, Background } from './BottomSheet.styled'

type BottomSheetProps = {
  onSubmit: () => void
  title: string
  buttonLabel: string
  children: ReactNode
  isOpen: boolean
  onClose: () => void
  icon?: IconId
}

const BottomSheet = ({ onSubmit, title, buttonLabel, children, isOpen, onClose, icon }: BottomSheetProps) => {
  const controls = useAnimation()

  useEffect(() => {
    if (isOpen) {
      controls.start({ y: '0' })
    } else {
      controls.start({ y: '100%' })
    }
  }, [isOpen, controls])

  return (
    <Wrapper>
      <Background isOpen={isOpen} />
      <BottomSheetContainer animate={controls} initial={{ y: '100%' }}>
        <Head>
          <Title>
            {icon && <Icon iconId={icon} size="24" onClick={() => onClose()} />}
            {title}
          </Title>
          <Anchor onClick={onClose}>
            <Icon iconId="close" size="24" onClick={() => onClose()} />
          </Anchor>
        </Head>
        <Content>{children}</Content>
        <Footer>
          <Button
            onClick={() => {
              onSubmit()
            }}
            className="confirm-button"
          >
            {buttonLabel}
          </Button>
        </Footer>
      </BottomSheetContainer>
    </Wrapper>
  )
}

export default BottomSheet
