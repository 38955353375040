import styled from 'styled-components'
import { hexToRGBA, toCssPrefix } from '@ecommerce/shared'

export const { toPrefix, cssPrefix } = toCssPrefix('Confirm__')

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  .${cssPrefix} {
    &confirm-payment {
      margin: 8px 0 8px;
    }
    &next-button {
      margin-bottom: 8px;
    }
    &termCheckbox {
      margin-bottom: 32px;
    }
    &alert-message {
      margin-top: 8px;
    }
  }
`

export const CheckboxOption = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;
  padding: 0;

  span {
    margin-left: 10px;
    color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
    font-size: 13px;
    a {
      color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
    }
  }
`
