import React, { useState, SetStateAction, useEffect } from 'react'
import {
  AuthWidget,
  AuthFormType,
  updateOrder,
  updateOrderMetadata,
  SignInReturn,
  useAuth,
  useShoppingCart,
} from '@ecommerce/shared'
import { Wrapper } from './MyData.styled'
import Card from '../../../Card'
import useAuthWidget from '../../../../hooks/useAuthWidget'
import secrets from '../../../../config/secrets'
import { UserInformation, getLoggedCustomerOrder } from '../..'
import { sendLoginEvent } from '../../../../utils/events'
import SkeletonScreen from '../SkeletonScreen'
import { useCheckout } from '../../checkout.context'

type Props = {
  refOrderId: React.RefObject<string>
  setOrderCustomer: React.Dispatch<React.SetStateAction<UserInformation>>
  orderCustomer: UserInformation
  setIsLoadingUserData: (isLoading: boolean) => void
  isLoadingUserData: boolean
  setShowAddress: (showAddress: boolean) => void
}

const MyData = ({
  refOrderId,
  setOrderCustomer,
  orderCustomer,
  setIsLoadingUserData,
  isLoadingUserData,
  setShowAddress,
}: Props) => {
  const { COUNTRY } = secrets

  const [showSignUp, setShowSignUp] = useState(false)
  const { getConnectifCart } = useShoppingCart()
  const { getEntityInfo } = useAuth()

  const { setCurrentStep } = useCheckout()
  const { closeWidget, getProps } = useAuthWidget()

  const showMyDelivery = () => {
    setCurrentStep(2)
    setShowAddress(true)
    closeWidget()
  }

  const showAuth = () => {
    if (!orderCustomer?.email) {
      setCurrentStep(1)
      setShowAddress(false)
    }
  }

  useEffect(() => {
    if (orderCustomer.email) {
      showMyDelivery()
    } else {
      showAuth()
    }

    return () => {
      closeWidget()
    }
  }, [orderCustomer.email])

  const updateCustomerOrder = async (userEmail: string, metadata: Record<string, string>, guest = false) => {
    if (!refOrderId.current) {
      return
    }

    try {
      await updateOrder({
        orderId: refOrderId.current,
        country: COUNTRY,
        attributes: {
          customer_email: userEmail,
          metadata: {},
          guest,
        },
      })

      await updateOrderMetadata({
        orderId: refOrderId.current,
        country: COUNTRY,
        metadata,
      })

      if (!guest) {
        const customer = getLoggedCustomerOrder()
        setOrderCustomer(customer)
      } else {
        const { firstName, lastName, birthdate, dni } = metadata
        setOrderCustomer({
          ownerId: null,
          firstName,
          lastName,
          birthdate,
          dni,
          email: userEmail,
          isAuth: false,
        })
      }
      showMyDelivery()
    } catch (error) {
      showAuth()
    }
  }

  const authFormsProps = {
    guest: {
      title: 'Comprar como invitado',
      subtitle: 'Podrás comprar como invitado si no tienes una cuenta Mi Coca-Cola',
      onFinished: async (metadata: Record<string, string>, userEmail: string) => {
        if (metadata && userEmail) {
          await updateCustomerOrder(userEmail, metadata, true)
        }
      },
      willCheckout: true,
    },
    signin: {
      title: 'Iniciar Sesión',
      onFinished: async (signInResponse?: SignInReturn) => {
        const cart = getConnectifCart()
        const entityInfo = getEntityInfo()
        sendLoginEvent(cart, entityInfo)
        if (signInResponse?.data) {
          const {
            data: { email, firstName, lastName, dni },
          } = signInResponse

          await updateCustomerOrder(email, {
            firstName,
            lastName,
            dni,
          })
        }
      },
    },
    signup: {
      title: 'Crear Cuenta',
      prehome: false,
      showBottomContent: false,
      showConfirmation: true,
      disableInputs: false,
    },
  }

  if (isLoadingUserData) {
    return <SkeletonScreen />
  }

  return (
    <Wrapper>
      {showSignUp && (
        <AuthWidget
          onClose={() => setShowSignUp(false)}
          title="Crear Cuenta"
          showWidget={() => null}
          closeWidget={() => setShowSignUp(false)}
          useModal
          formSettings={getProps(AuthFormType.SIGNUP, authFormsProps)}
          setIsLoadingUserData={setIsLoadingUserData}
        />
      )}
      <Card>
        <AuthWidget
          onClose={() => null}
          title="Iniciar sesión"
          showWidget={() => setShowSignUp(true)}
          closeWidget={() => null}
          useModal={false}
          formSettings={getProps(AuthFormType.SIGNIN, authFormsProps)}
          setIsLoadingUserData={setIsLoadingUserData}
        />
      </Card>
      <Card>
        <AuthWidget
          onClose={() => null}
          title="Continuar como invitado"
          showWidget={() => null}
          closeWidget={() => null}
          useModal={false}
          formSettings={getProps(AuthFormType.GUEST, authFormsProps)}
          setIsLoadingUserData={setIsLoadingUserData}
        />
      </Card>
    </Wrapper>
  )
}

export default MyData
