import React from 'react'
import { useLocation, BillingData } from '@ecommerce/shared'
import { Wrapper, Title, Description } from './SecurePayment.styled'
import { LockIcon } from '../../../../Icons/LockIcon'
import BillingForm from './BillingForm'
import { useCheckout } from '../../../checkout.context'

type Props = {
  privacyPolicyLink: string
  onBillingSubmit: (data: BillingData) => void
  onBillingChange: (isShown: boolean) => void
}
const SecurePayment = ({ privacyPolicyLink, onBillingSubmit, onBillingChange }: Props) => {
  const { isBolivia } = useLocation()
  const isBo = isBolivia()
  const { isExecutingPayment } = useCheckout()
  return (
    <Wrapper>
      <Title>
        <LockIcon />
        Pago Seguro
      </Title>
      <Description>
        Esta transacción se está realizando bajo un sistema seguro de{' '}
        <a className="payment-source-link" target="_blank" rel="noopener noreferrer" href={privacyPolicyLink}>
          Políticas de seguridad
        </a>
      </Description>
      {!isBo && (
        <BillingForm onSubmit={onBillingSubmit} onChange={onBillingChange} isExecutingPayment={isExecutingPayment} />
      )}
    </Wrapper>
  )
}

export default SecurePayment
